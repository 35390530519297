import { ITimeLineHelper } from '@/core/managers/timeLineHelper'
import { timeDay } from 'd3'
import { AssetRow, EntryAssetRow } from '@/core/managers/entriesForAsset/entriesForAssetsModel'
import { APIEntriesForAsset } from '@/core/managers/entriesForAsset/entriesForAssetApi'

export interface IEntriesForAssetMapper {
  fetchDecoupling: (item:APIEntriesForAsset)=> AssetRow[]
}

export const CreateEntriesForAssetMapper = function (timelineHelper : ITimeLineHelper): IEntriesForAssetMapper {
  // reset startDate if inf TimeLineFirstDay
  const _mapStartDate = function (periodStart:Date) {
    const timeLineStart = timelineHelper.timelineInfo.firstDate
    const count = timeDay.count(timeLineStart, periodStart)
    if (count > 0) {
      return periodStart
    } else {
      return timelineHelper.timelineInfo.firstDate
    }
  }

  // reset endDate if sup TimeLineLastDay
  const _mapEndDate = function (periodEnd:Date) {
    const timeLineEnd = timelineHelper.timelineInfo.lastDate
    const count = timeDay.count(timeLineEnd, periodEnd)
    if (count > 0) {
      return timelineHelper.timelineInfo.lastDate
    } else {
      return periodEnd
    }
  }

  const _isDisplayReason = function (_start:Date, _end:Date):boolean {
    const limitDisplayReason = 13
    return timeDay.count(_start, _end) >= limitDisplayReason
  }

  // calc offset period
  const _offsetWidthPeriod = function (startPeriod : Date): number {
    const _start = _mapStartDate(startPeriod)
    const width = timelineHelper.colWith
    return (timeDay.count(timelineHelper.timelineInfo.firstDate, _start) * width)
  }
  // call length period
  const _pixelWidthPeriod = function (startPeriod:Date, endPeriod : Date):number {
    const _start = _mapStartDate(startPeriod)
    const _end = _mapEndDate(endPeriod)
    const width = timelineHelper.colWith
    const _count = timeDay.count(_start, _end)
    return ((_count === 0 ? 1 : _count) * width)
  }

  // todo remove helper parseDate
  return {
    fetchDecoupling: function (item:APIEntriesForAsset):AssetRow[] {
      return item.assets.reduce((list:AssetRow[], asset) => {
        list.push({
          id: asset.id,
          name: asset.name,
          entries: asset.entries.reduce((listEntry:EntryAssetRow[], entry) => {
            const _start = new Date(entry.start_date)
            const _end = new Date(entry.end_date)
            if (_start <= timelineHelper.timelineInfo.lastDate && _end >= timelineHelper.timelineInfo.firstDate) {
              listEntry.push({
                daily_rate: entry.daily_rate,
                editable: entry.editable,
                deletable: entry.deletable,
                draw: {
                  isStartCropped: _mapStartDate(_start) === timelineHelper.timelineInfo.firstDate,
                  isEndCropped: _mapEndDate(_end) === timelineHelper.timelineInfo.lastDate,
                  offsetWidth: _offsetWidthPeriod(_start),
                  lengthWidth: _pixelWidthPeriod(_start, _end)
                },
                start_date: _start,
                end_date: _end,
                reason: entry.reason,
                isDisplayReason: _isDisplayReason(_start, _end),
                status: `${entry.status} ${entry.status.toLowerCase().indexOf('missing') > -1 ? '' : 'hire'}`,
                isOff: entry.status.toLowerCase().indexOf('off') > -1,
                isOn: entry.status.toLowerCase().indexOf('on') > -1,
                isMissing: entry.status.toLowerCase().indexOf('missing') > -1,
                status_color: entry.status_color,
                id: entry.id
              } as EntryAssetRow)
            }
            return listEntry
          }, [])
        })
        return list
      }, [])
    }
  }
}
