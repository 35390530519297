import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "mt-6" }
const _hoisted_2 = { class: "flex flex-col lg:flex-row text-gray-200 w-full" }
const _hoisted_3 = {
  key: 0,
  class: "lg:w-1/6 hidden lg:block"
}
const _hoisted_4 = {
  key: 1,
  class: "lg:w-1/6 hidden lg:block"
}
const _hoisted_5 = {
  key: 2,
  class: "lg:w-1/6 hidden lg:block"
}
const _hoisted_6 = {
  key: 3,
  class: "lg:w-1/6 hidden lg:block"
}
const _hoisted_7 = { class: "w-[70%] bg-bg-0/50 backdrop-blur-md h-full transit" }
const _hoisted_8 = { class: "grow ml-0 lg:ml-6 w-full lg:max-w-[80%]" }
const _hoisted_9 = {
  key: 0,
  class: "flex lg:hidden justify-center my-7"
}
const _hoisted_10 = { class: "ml-3 body-1 font-bold" }
const _hoisted_11 = {
  key: 1,
  class: "flex lg:hidden justify-center my-7"
}
const _hoisted_12 = {
  key: 2,
  class: "flex lg:hidden justify-center my-7"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_DashboardsListMenu = _resolveComponent("DashboardsListMenu")!
  const _component_FilterIcon = _resolveComponent("FilterIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("h1", null, "Dashboards", -1)),
    _createVNode(_component_SearchInput, {
      placeholder: `Search for dashboards...`,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('search-dashboard', _ctx.search))),
      onClearInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('search-dashboard', ''))),
      class: "my-4 w-fit",
      modelValue: _ctx.search,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.search) = $event))
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.error && !_ctx.loading && _ctx.services.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (report, i) => {
              return (_openBlock(), _createBlock(_component_DashboardsListMenu, {
                key: i,
                dashboard: report,
                onShowDetail: ($event: any) => (_ctx.handleDetailDashboard($event, report)),
                onShowService: ($event: any) => (_ctx.handleServiceDashboard($event, report)),
                allActive: _ctx.allActive,
                allActiveInCategory: _ctx.allActiveInCategory
              }, null, 8, ["dashboard", "onShowDetail", "onShowService", "allActive", "allActiveInCategory"]))
            }), 128))
          ]))
        : (!_ctx.error && !_ctx.loading && !_ctx.services.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("allReports.notFound")), 1))
          : (_ctx.error && !_ctx.loading && !_ctx.services.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.error), 1))
            : (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
                    return _createVNode(_component_DashboardsListMenu, {
                      key: i,
                      loading: _ctx.loading
                    }, null, 8, ["loading"])
                  }), 64))
                ]))
              : _createCommentVNode("", true),
      (!_ctx.error && !_ctx.loading && _ctx.services.length)
        ? (_openBlock(), _createElementBlock("div", {
            key: 4,
            class: _normalizeClass(["block lg:hidden fixed h-[calc(100vh-56px-63px)] z-10 top-[63px] transit w-screen", _ctx.filtersDisplayed ? 'left-0' : '-left-full']),
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeFilters && _ctx.closeFilters(...args)))
          }, [
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (report, i) => {
                return (_openBlock(), _createBlock(_component_DashboardsListMenu, {
                  key: i,
                  dashboard: report,
                  onShowDetail: ($event: any) => (_ctx.handleDetailDashboard($event, report)),
                  onShowService: ($event: any) => (_ctx.handleServiceDashboard($event, report))
                }, null, 8, ["dashboard", "onShowDetail", "onShowService"]))
              }), 128))
            ])
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.services.length && !_ctx.error)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("button", {
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.openFilters && _ctx.openFilters(...args))),
                class: "text-text-title stroke-text-title py-2 px-4 border border-text-title rounded-lg flex items-center filters-button"
              }, [
                _createVNode(_component_FilterIcon, { class: "h-4" }),
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("allDashboards.filters")), 1)
              ])
            ]))
          : (!_ctx.error && !_ctx.loading && !_ctx.services.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t("allReports.notFound")), 1))
            : (_ctx.error && !_ctx.loading && !_ctx.services.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.error), 1))
              : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}