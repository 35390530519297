import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { toRef } from 'vue'

import { KpiInfo } from '@/core/model/kpi.model'
import { FilterFormatted } from '@/core/model/filter.model'
import { useKpiRequestor } from '@/components/analytics/kpi/useKpiRequestor'
import kpiCard from '@/components/analytics/kpi/card.vue'
import kpiCardEmpty from '@/components/analytics/kpi/card.empty.vue'
import kpiCardError from '@/components/analytics/kpi/card.error.vue'
import kpiCardLoading from '@/components/analytics/kpi/card.loading.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'kpi',
  props: {
    kpi: {},
    filtersApplied: {}
  },
  setup(__props: any) {

const props = __props

const { values, error, isLoading, isError } = useKpiRequestor(props.kpi, toRef(props.filtersApplied))


return (_ctx: any,_cache: any) => {
  return (_unref(isLoading))
    ? (_openBlock(), _createBlock(kpiCardLoading, { key: 0 }))
    : (_unref(isError) || !_unref(values) || _unref(values).kpis.length === 0)
      ? (_openBlock(), _createBlock(kpiCardEmpty, {
          key: 1,
          title: _ctx.kpi.label
        }, null, 8, ["title"]))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_unref(values)?.kpis, (k, i) => {
          return (_openBlock(), _createBlock(kpiCard, {
            key: i,
            label: k.label,
            title: _ctx.kpi.label,
            value: k.value,
            unit: k.unit,
            type: _ctx.kpi.type,
            description: _ctx.kpi.description
          }, null, 8, ["label", "title", "value", "unit", "type", "description"]))
        }), 128))
}
}

})