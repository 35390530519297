import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "_ngcontent-wlf-c130": "",
  width: "59",
  height: "21",
  viewBox: "0 0 59 21",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  class: "icon"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("rect", {
      "_ngcontent-wlf-c130": "",
      x: "1",
      y: "17",
      width: "58",
      height: "1",
      fill: "currentColor"
    }, null, -1),
    _createElementVNode("rect", {
      "_ngcontent-wlf-c130": "",
      y: "14",
      width: "1",
      height: "7",
      fill: "currentColor"
    }, null, -1),
    _createElementVNode("rect", {
      "_ngcontent-wlf-c130": "",
      x: "58",
      y: "14",
      width: "1",
      height: "7",
      fill: "currentColor"
    }, null, -1),
    _createElementVNode("path", {
      "_ngcontent-wlf-c130": "",
      d: "M4.61669 9.5V0.5L44.0916 0.5C46.5334 0.5 49.4099 1.30699 51.6835 2.33003C52.8181 2.84055 53.7799 3.39477 54.4488 3.91055C54.7842 4.1691 55.0309 4.40654 55.1893 4.61156C55.3546 4.82554 55.3838 4.95237 55.3838 5C55.3838 5.05518 55.3521 5.18664 55.1878 5.40161C55.0296 5.60858 54.7832 5.84721 54.4482 6.10633C53.7799 6.62333 52.8188 7.17611 51.6843 7.68417C49.4113 8.70215 46.5344 9.5 44.0916 9.5L4.61669 9.5Z",
      fill: "currentColor",
      stroke: "currentColor"
    }, null, -1)
  ])))
}