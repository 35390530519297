import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "p-1 dark:bg-slate-800 absolute rounded-md bg-white/50",
  "data-element-id": "zonePopover",
  "data-sticky-parent": "stickyMenuRight",
  style: {"width":"300px","box-shadow":"0 0 10px rgba(169,184,219,.4)"}
}
const _hoisted_2 = { class: "flex items-center pl-2.5 py-2.5 border-b border-gray-700" }
const _hoisted_3 = { class: "ml-2" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-col items-center py-4 dark:text-white"
}
const _hoisted_5 = {
  key: 1,
  class: "flex flex-col items-center py-4 dark:text-white"
}
const _hoisted_6 = {
  key: 2,
  "data-accordion-type": "wrapper",
  class: "dark:bg-slate-800 dark:text-white rounded-md p-2"
}
const _hoisted_7 = ["data-accordion-expanded"]
const _hoisted_8 = {
  "data-accordion-type": "link",
  class: "flex items-center",
  href: "#"
}
const _hoisted_9 = { class: "grow font-semibold" }
const _hoisted_10 = {
  key: 0,
  class: "fa fa-chevron-down"
}
const _hoisted_11 = {
  "data-accordion-type": "body",
  class: "accordion-body custom-scrollbar"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_toggle_concession = _resolveComponent("toggle-concession")!
  const _component_zone_row = _resolveComponent("zone-row")!
  const _directive_accordion = _resolveDirective("accordion")!
  const _directive_sticky = _resolveDirective("sticky")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "grow text-xl dark:text-white font-bold" }, "Zones", -1)),
      _createVNode(_component_router_link, {
        to: { query :  { ..._ctx.$route.query, popover: undefined}  },
        class: "text-white"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("span", { class: "fa-stack" }, [
            _createElementVNode("i", { class: "fa-solid text-slate-600 fa-circle fa-circle fa-stack-2x" }),
            _createElementVNode("i", {
              class: "fa-regular fa-circle-xmark fa-stack-1x dark:text-white",
              style: {"font-size":"1.2em"}
            })
          ], -1)
        ])),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_toggle_concession)
    ]),
    (_ctx.state.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[3] || (_cache[3] = [
          _createElementVNode("i", { class: "fa-solid fa-spinner fa-spin" }, null, -1),
          _createElementVNode("span", null, "Loading", -1)
        ])))
      : (_ctx.noCategory)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[4] || (_cache[4] = [
            _createElementVNode("i", { class: "fa-solid fa-empty-set" }, null, -1),
            _createElementVNode("span", null, "No data", -1)
          ])))
        : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.group, (group, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "mt-2",
                "data-accordion-expanded": index === 0,
                style: _normalizeStyle([{ overflowY : index === 0 ? 'auto' : 'hidden' }])
              }, [
                _createElementVNode("a", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(group.name), 1),
                  (_ctx.atLeastOneCategory)
                    ? (_openBlock(), _createElementBlock("i", _hoisted_10))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("section", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.list, (zone, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (zone.type === group.name)
                        ? (_openBlock(), _createBlock(_component_zone_row, {
                            key: 0,
                            zone: zone,
                            onUpdate: _cache[0] || (_cache[0] = (args) => _ctx.update(args))
                          }, null, 8, ["zone"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ])
              ], 12, _hoisted_7))
            }), 128))
          ])), [
            [_directive_accordion]
          ])
  ])), [
    [_directive_sticky]
  ])
}