
import { defineComponent, PropType } from 'vue'
import ServicePreview from '@/components/analytics/services/previews/preview.vue'
import { ServiceModel } from '@/core/model/service.model'
export default defineComponent({
  components: {
    ServicePreview
  },
  props: {
    current: Number,
    assetId: Number,
    assetFilter: Number,
    periodFilter: Number,
    direction: String,
    sources: {
      type: Array as PropType<ServiceModel[]>,
      require: true
    }
  }
})
