import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import Tag from './tag.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'tag.loading',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Tag, null, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("span", { class: "skeleton w-24 inline-block" }, null, -1)
    ])),
    _: 1
  }))
}
}

})