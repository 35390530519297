import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "19.066",
  viewBox: "0 0 20 19.066"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      id: "home",
      transform: "translate(0 -0.467)"
    }, [
      _createElementVNode("g", {
        id: "Groupe_4470",
        "data-name": "Groupe 4470"
      }, [
        _createElementVNode("path", {
          id: "Tracé_7906",
          "data-name": "Tracé 7906",
          d: "M10,.467,0,9.151,1.024,10.33l.936-.813v7.674A2.344,2.344,0,0,0,4.3,19.533H8.673v-7.65h2.732v7.65H15.7a2.344,2.344,0,0,0,2.342-2.342V9.517l.936.813L20,9.151Zm6.479,16.724a.781.781,0,0,1-.781.781H12.966v-7.65H7.112v7.65H4.3a.781.781,0,0,1-.781-.781V8.161L10,2.535l6.479,5.626Z",
          fill: "currentColor"
        })
      ])
    ], -1)
  ])))
}