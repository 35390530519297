import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  id: "Capa_1",
  "enable-background": "new 0 0 512 512",
  height: "512",
  viewBox: "0 0 512 512",
  width: "512",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g><path d=\"m409.087 0-28.284 28.79c34.042 34.042 52.79 79.219 52.79 127.21s-18.748 93.168-52.79 127.21l28.284 28.284c41.597-41.597 64.505-96.819 64.505-155.495s-22.907-114.402-64.505-155.999z\"></path><path d=\"m352.549 254.957c26.467-26.467 41.043-61.611 41.043-98.957s-14.576-72.489-41.043-98.957l-28.284 28.284c18.912 18.912 29.328 44.011 29.328 70.672s-10.416 51.76-29.328 70.672z\"></path><path d=\"m131.197 28.79-28.284-28.79c-41.598 41.597-64.506 97.325-64.506 156s22.908 113.898 64.505 155.495l28.284-28.284c-34.042-34.042-52.79-79.219-52.79-127.21s18.749-93.169 52.791-127.211z\"></path><path d=\"m159.451 254.957 28.284-28.284c-18.912-18.912-29.328-44.011-29.328-70.672s10.416-51.76 29.328-70.672l-28.284-28.284c-26.467 26.467-41.043 61.611-41.043 98.957s14.575 72.487 41.043 98.955z\"></path><path d=\"m313.593 156c0-33.08-26.92-60-60-60s-60 26.92-60 60c0 24.29 14.51 45.25 35.31 54.68l-81.43 301.32h212.24l-81.43-301.32c20.8-9.43 35.31-30.39 35.31-54.68zm-60-20c10.957 0 20 8.902 20 20 0 10.939-8.89 20-20 20-11.113 0-20-9.065-20-20 0-11.079 9.021-20 20-20zm53.88 336h-107.76l53.88-199.34z\"></path></g>", 1)
  ])))
}