import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-1/12 p-2 md:p-3 align-middle" }
const _hoisted_2 = { class: "p-2 md:p-3 align-middle" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "w-1/12 md:w-2/12 p-2 md:p-3 align-middle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Star = _resolveComponent("Star")!
  const _component_LoaderIcon = _resolveComponent("LoaderIcon")!

  return (_openBlock(), _createElementBlock("table", {
    class: "table-auto w-full text-text-prim border-b border-text-alt cursor-pointer",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.seeAssetDetails && _ctx.seeAssetDetails(...args)))
  }, [
    _createElementVNode("tbody", null, [
      _createElementVNode("tr", null, [
        _createElementVNode("td", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["w-[100px] h-14 bg-cover rounded-lg relative z-2", { 'skeleton-bg': _ctx.loadingCard }]),
            style: _normalizeStyle(_ctx.loadingCard ? '' : `background-image: url('${_ctx.imgUrl ? _ctx.imgUrl : _ctx.img}');`)
          }, null, 6)
        ]),
        _createElementVNode("td", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.flag)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([`fi fi-${_ctx.flag} text-base mr-3`, _ctx.flag ? 'mr-3' : ''])
                }, null, 2))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass({ skeleton: _ctx.loadingCard })
            }, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.name), 1)
            ], 2)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["body-3 body-alt mt-1", { 'skeleton-type': _ctx.loadingCard }])
          }, _toDisplayString(_ctx.type), 3)
        ]),
        _createElementVNode("td", _hoisted_4, [
          (!_ctx.loading)
            ? (_openBlock(), _createBlock(_component_Star, {
                key: 0,
                onClick: _ctx.changeFavStatus,
                class: _normalizeClass(["cursor-pointer p-1.5 !h-6 !w-6 stroke-text-alt", _ctx.isFavourite ? 'fleet-card__fav fill-text-alt stroke-text-alt' : 'none' ])
              }, null, 8, ["onClick", "class"]))
            : (_openBlock(), _createBlock(_component_LoaderIcon, {
                key: 1,
                class: "p-1.5 !h-6 !w-6"
              }))
        ])
      ])
    ])
  ]))
}