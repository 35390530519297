import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "py-5 d-flex justify-center items-center"
}
const _hoisted_2 = { class: "text-primary me-1 inline-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_progress_circular, { indeterminate: "" })
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_v_row, { class: "py-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "4",
              class: "text-left"
            }, {
              default: _withCtx(() => [
                _cache[0] || (_cache[0] = _createElementVNode("span", { class: "text-primary me-1 inline-block" }, "Daily rate:", -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.entry.dailyRate) + "%", 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "4",
              class: "text-left"
            }, {
              default: _withCtx(() => [
                _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-primary me-1 inline-block" }, "Reason:", -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.entry.reasonName), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "4",
              class: "text-left"
            }, {
              default: _withCtx(() => [
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-primary me-1 inline-block" }, "Project:", -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.entry.projectName), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "py-4" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entry.robList, (rob, index) => {
              return (_openBlock(), _createBlock(_component_v_col, {
                cols: "12",
                md: "4",
                class: "text-left",
                key: index
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(rob.product) + ":", 1),
                  _createElementVNode("span", null, _toDisplayString(rob.quantity) + _toDisplayString(rob.unit), 1)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "py-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              class: "text-left"
            }, {
              default: _withCtx(() => [
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "text-primary me-1 inline-block" }, "Comments:", -1)),
                _createElementVNode("span", null, _toDisplayString(_ctx.entry.description), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ], 64))
}