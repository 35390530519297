import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  height: "512pt",
  viewBox: "0 0 512 512",
  width: "512pt",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m199.945312 359.898438h67.980469v39.988281h-67.980469zm-74.980468-4.996094c-13.804688 0-24.992188 11.1875-24.992188 24.992187 0 13.800781 11.1875 24.992188 24.992188 24.992188 13.804687 0 24.992187-11.191407 24.992187-24.992188 0-13.804687-11.1875-24.992187-24.992187-24.992187zm276.921875-121.96875h-201.941407v39.988281h201.941407zm-276.921875-4.996094c-13.804688 0-24.992188 11.1875-24.992188 24.992188 0 13.804687 11.1875 24.992187 24.992188 24.992187 13.804687 0 24.992187-11.1875 24.992187-24.992187 0-13.804688-11.1875-24.992188-24.992187-24.992188zm276.921875-114.96875h-201.941407v39.988281h201.941407zm-276.921875-5c-13.804688 0-24.992188 11.191406-24.992188 24.992188 0 13.804687 11.1875 24.996093 24.992188 24.996093 13.804687 0 24.992187-11.191406 24.992187-24.996093 0-13.800782-11.1875-24.992188-24.992187-24.992188zm-84.976563 343.90625v-391.890625c0-11.027344 8.96875-19.996094 19.996094-19.996094h391.890625c11.023438 0 19.992188 8.96875 19.992188 19.996094v225.9375h39.988281v-225.9375c0-33.074219-26.90625-59.984375-59.980469-59.984375h-391.890625c-33.074219 0-59.984375 26.910156-59.984375 59.984375v391.890625c0 33.074219 26.910156 59.980469 59.984375 59.980469h230.933594v-39.988281h-230.933594c-11.027344 0-19.996094-8.96875-19.996094-19.992188zm443.734375 60.125-39.820312-39.820312c-13.472656 8.050781-29.210938 12.683593-46.011719 12.683593-49.613281 0-89.976563-40.363281-89.976563-89.972656 0-49.613281 40.363282-89.976563 89.976563-89.976563 49.609375 0 89.972656 40.363282 89.972656 89.976563 0 18.355469-5.53125 35.441406-15.003906 49.691406l39.140625 39.140625zm-85.832031-67.125c27.5625 0 49.984375-22.421875 49.984375-49.984375s-22.421875-49.988281-49.984375-49.988281-49.988281 22.425781-49.988281 49.988281 22.425781 49.984375 49.988281 49.984375zm0 0" }, null, -1)
  ])))
}