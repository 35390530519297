import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  version: "1.1",
  id: "Capa_1",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 512 512",
  style: {"enable-background":"new 0 0 512 512"},
  "xml:space": "preserve"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g><g><g><path d=\"M124,151H67.157C104.945,82.816,176.533,40,256,40c119.103,0,216,96.897,216,216h40c0-68.38-26.629-132.667-74.98-181.02 C388.667,26.629,324.38,0,256,0c-51.89,0-101.863,15.445-144.519,44.666C82.677,64.398,58.471,89.482,40,118.491V65H0v126h124 V151z\"></path><path d=\"M388,365h54.563C404.205,430.831,333.802,472,256,472c-119.103,0-216-96.897-216-216H0 c0,68.38,26.629,132.667,74.98,181.02C123.333,485.371,187.62,512,256,512c51.684,0,101.491-15.333,144.04-44.34 c29.005-19.774,53.38-44.983,71.96-74.163V450h40V325H388V365z\"></path><path d=\"M220.232,295.108l42.701,117.309l110.713-269.95l-270.41,110.902L220.232,295.108z M265.157,301.583l-13.77-37.828 l-36.396-12.985l85.363-35.01L265.157,301.583z\"></path></g></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>", 16)
  ])))
}