import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  height: "512pt",
  viewBox: "0 0 512 512",
  width: "512pt",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m472 434c0 20.953125-17.269531 38-38.5 38-20.648438 0-37.546875-16.128906-38.457031-36.289062 29.058593-52.09375 51.191406-113.648438 59.703125-166.53125l2.996094-18.613282-62.742188-13.945312v-76.621094h30v-40h-70v-76h-80v-44h-40v44h-80v76h-70v40h30v76.621094l-62.742188 13.945312 2.996094 18.613282c11.152344 69.285156 43.921875 134.816406 61.703125 166.550781-.921875 20.152343-17.816406 36.269531-38.457031 36.269531-21.230469 0-38.5-17.046875-38.5-38h-40c0 43.011719 35.214844 78 78.5 78 23.769531 0 45.09375-10.566406 59.5-27.207031 14.40625 16.640625 35.730469 27.207031 59.5 27.207031 23.21875 0 44.113281-10.066406 58.5-26.039062 14.386719 15.972656 35.28125 26.039062 58.5 26.039062 23.773438 0 45.09375-10.566406 59.503906-27.203125 14.40625 16.636719 35.726563 27.203125 59.496094 27.203125 43.285156 0 78.5-34.988281 78.5-78zm-277-350h120v36h-120zm-40 76h200v67.734375l-100-22.222656-100 22.222656zm198 274c0 20.953125-17.269531 38-38.5 38s-38.5-17.046875-38.5-38h-40c0 20.953125-17.269531 38-38.5 38s-38.5-17.046875-38.5-38v-5.410156l-2.730469-4.675782c-26.945312-46.152343-47.933593-98.070312-57.941406-142.609374l156.671875-34.816407 156.753906 34.832031c-11.738281 54.226563-35.894531 108.113282-56.023437 142.59375l-2.730469 4.675782zm-17-81c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20 8.953125-20 20-20 20 8.953125 20 20zm-121 0c0 11.046875-8.953125 20-20 20s-20-8.953125-20-20 8.953125-20 20-20 20 8.953125 20 20zm0 0" }, null, -1)
  ])))
}