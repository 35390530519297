import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-full w-full" }
const _hoisted_2 = { class: "h-full w-full flex flex-col" }
const _hoisted_3 = {
  key: 0,
  class: "w-2/5"
}
const _hoisted_4 = { class: "relative" }
const _hoisted_5 = {
  key: 0,
  class: "body body-1"
}
const _hoisted_6 = { class: "!text-text-alt" }

import { KpiInfo, KpiModel } from '@/core/model/kpi.model'
import { ChartInfo, ChartModel } from '@/core/model/chart.model'
import { getVisualComponent } from '@/components/analytics/charts/visuals/index'
import { IGeneratorOption } from '@/core/charts/ichartgenerator.model'
import Gauge from '@/components/analytics/kpi/visuals/gauge.kpi.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'chart-and-gauge.kpi',
  props: {
    chartInfo: {},
    kpiInfo: {},
    chart: {},
    kpi: {}
  },
  setup(__props: any) {

const props = __props

const options: IGeneratorOption = {
  height: props.chartInfo.type === 'PIE' ? '125px' : '87px',
  isDetailed: false,
  title: props.chartInfo.label,
  description: props.chartInfo.description,
  legends: {
    enable: false
  },
  margin: [0, 0, 0, 0]
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.kpi)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kpi.kpis, (kpiValue, i) => {
              return (_openBlock(), _createElementBlock("div", { key: i }, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(Gauge, {
                    title: _ctx.kpiInfo.label,
                    value: kpiValue.value ? kpiValue.value.toString() : '0',
                    unit: kpiValue.unit,
                    type: _ctx.kpiInfo.type,
                    class: "relative"
                  }, null, 8, ["title", "value", "unit", "type"])
                ]),
                (_ctx.kpiInfo.type !== 'SOLID_GAUGE')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(kpiValue.label || _ctx.kpiInfo.label), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.chart)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(getVisualComponent)(_ctx.chartInfo.type)), {
            key: 1,
            values: _ctx.chart,
            options: options,
            class: _normalizeClass(["h-full boorder", _ctx.chartInfo.type === 'PIE' ? 'w-3/5' : 'mx-[-1rem]'])
          }, null, 8, ["values", "class"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})