
import { defineComponent, DirectiveBinding, inject, PropType } from 'vue'
import { ITimeLineHelper } from '@/core/managers/timeLineHelper'
import { EntryAssetRow } from '@/core/managers/entriesForAsset/entriesForAssetsModel'

export default defineComponent({
  name: 'timeLineRowLeft',
  components: {
    // timelineCol
  },
  props: {
    asset_id: {
      type: Number,
      required: true
    },
    entries: {
      type: Array as PropType<EntryAssetRow[]>,
      required: true
    }
  },
  setup () {
    const timelineHelper = inject('timelineHelper') as ITimeLineHelper
    return { timelineHelper }
  },
  directives: {
    displayContent: {
      mounted (element: HTMLElement, binding: DirectiveBinding) {
        const periodWidth = binding.value.periodWidth
        const elementWidth = 15 + element.getBoundingClientRect().width
        if (periodWidth < elementWidth) {
          element.style.display = 'none'
        }
      }
    }
  }
})
