import { IChartGenerator, IGeneratorOption } from '@/core/charts/ichartgenerator.model'
import { ChartModel, ChartCategoryModel, ChartOptionsModel, ExportingOptions, LegendOptions, NavigationOptions, PlotOptions, SerieOptions, TooltipOptions, xAxisOptions, yAxisOptions } from '@/core/model/chart.model'
import ColoursService, { bg0, bg2, textAlt, textGray400, textGray600, textPrim, textWhite, semanticActivate } from '@/core/services/colours.service'
import router from '@/router'
import FormatService from '../services/format.service'
import Highcharts from 'highcharts'
import HighchartsNoData from 'highcharts/modules/no-data-to-display'

HighchartsNoData(Highcharts)
Highcharts.setOptions({ lang: { noData: 'Unavailable' } })

export const chartTypes = {
  bar: 'column',
  line: 'spline',
  pie: 'pie',
  LINE: 'spline',
  PIE: 'pie',
  BAR: 'column',
  'H-BAR': 'column',
  BARXLINE: 'BARXLINE'

}
// const columnCharts = [chartTypes.bar, chartTypes['H-BAR'], chartTypes.BAR]

export const lineCharts = [chartTypes.line, chartTypes.LINE]

export const pieCharts = [chartTypes.pie, chartTypes.PIE]

export abstract class BaseChartGenerator implements IChartGenerator {
  protected get Type (): string { return 'column' }
  protected get OverviewType (): string { return this.Type }
  protected get DefaultFont (): string { return 'Arial' }

  public createChart (content: ChartModel, options: IGeneratorOption): Partial<ChartOptionsModel> | Record<string, never> | any {
    const data = this.createSeries(content, options.isDetailed)
    const yAxisOptions = this.createYOptions(data ?? [], content.categories, options.isDetailed)
    return {
      chart: {
        zoomType: options.isDetailed ? 'x' : null,
        panning: true,
        panKey: 'shift',
        resetZoomButton: {
          theme: {
            fill: semanticActivate,
            stroke: semanticActivate,
            style: {
              color: bg0,
              fontWeight: 'bold'
            },
            r: 5,
            states: {
              hover: {
                fill: '#afd2fc',
                stroke: '#afd2fc',
                style: {
                  color: bg0
                }
              }
            }
          }
        },
        height: options.height,
        backgroundColor: 'transparent',
        style: {
          fontFamily: this.DefaultFont
        },
        margin: options.margin // this.defineMargins(options.isDetailed, options.previewType)
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: textAlt
        }
      },
      title: {
        text: options.isDetailed ? options.title : null,
        style: { color: textPrim, fontWeight: 'bold' },
        margin: 50
      },
      subtitle: {
        text: `${options.isDetailed ? options.description : ''}<br/>${options.isDetailed && !pieCharts.includes(chartTypes[this.Type as keyof typeof chartTypes]) ? (document.ontouchstart === undefined ? 'Click and drag in the plot area to zoom in. Hold down shift key to pan.' : 'Pinch the chart to zoom in') : ''}`,
        style: { color: textAlt }
      },
      tooltip: this.tooltip(options.isDetailed),
      plotOptions: this.plotOptions(options.isDetailed),
      legend: this.chartLegend(options.isDetailed, options.legends.enable),
      dataLabels: { enabled: options.isDetailed },
      xAxis: this.xAxis(content.xAxis, options.isDetailed, options.title),
      series: data,
      yAxis: yAxisOptions,
      credits: {
        enabled: false
      },
      exporting: this.exporting(options.isDetailed),
      navigation: this.navigation()
    }
  }

  protected createSeries (content: ChartModel, detailed?: boolean): Partial<SerieOptions>[]|undefined {
    if (content.categories.length === 0) {
      return undefined
    }

    const series = []
    const finalType = detailed ? this.Type : this.OverviewType
    let categoryIndex = 0
    for (const category of content.categories) {
      for (const serie of category.series) {
        const values: any[] = []
        for (const label of content.xAxis) {
          const value = serie.values.find(v => v.label === label)?.value
          values.push({
            y: value ? parseFloat(value?.toString()) : 0
          })
        }
        const color = ColoursService.getColour(serie.name ?? '')
        series.push({
          name: serie.name ?? '',
          type: finalType,
          data: values,
          yAxis: categoryIndex,
          color,
          borderColor: ColoursService.colourLightness(color, 1.8),
          borderWidth: 1
        })
      }
      categoryIndex++
    }
    return series
  }

  protected getColor (label: string): string {
    return ColoursService.getColour(label)
  }

  protected tooltip (detailed: boolean, gauge = false): TooltipOptions {
    return {
      enabled: true,
      crosshairs: !gauge,
      shared: true,
      backgroundColor: bg0,
      borderRadius: 10,
      borderWidth: 1,
      borderColor: textGray600,
      padding: detailed ? 15 : !detailed && router.currentRoute.value.name !== 'assetDetail' ? 5 : 1,
      style: { color: textWhite, fontFamily: this.DefaultFont, fontSize: detailed ? '12px' : '12px' },
      useHTML: true,
      headerFormat: this.tooltipHeader(detailed),
      pointFormat: this.tooltipPoint(detailed),
      footerFormat: '</table>'
    }
  }

  protected tooltipHeader (detailed: boolean, gauge = false): string {
    return `<table style="position: relative; z-index: 10"><tr><th colspan="2" style="text-align:center ; color: ${textAlt}; padding-bottom: ${detailed ? '12px' : !detailed && gauge ? '2px' : '6px'}">{point.key}</th></tr>`
  }

  protected tooltipPoint (detailed: boolean, gauge = false): string {
    return `<tr><td style="display: flex; align-items: center; padding-right: ${detailed ? '12px' : !detailed && gauge ? '2px' : '6px'}; overflow: hidden; whitespace: nowrap;"><div style="background:{point.color}; height: 8px; width: 8px; min-width: 8px; border-radius: 50%; margin-right: 4px"></div> {series.name} </td>` +
    '<td style="text-align: right"><b>{point.y}</b></td></tr>'
  }

  // eslint-disable-next-line
  protected plotOptions (detailed: boolean): PlotOptions {
    return {
      series: {
        dataSorting: {
          matchByName: false,
          enabled: false
        },
        enableMouseTracking: true,
        fillOpacity: 0.2,
        stacking: '',
        opacity: 1,
        marker: { enabled: false, fillColor: 'transparent', symbol: 'circle', lineWidth: 2, lineColor: undefined },
        states: {
          inactive: { opacity: 1 }
        },
        pointPlacement: detailed ? undefined : 'on'
      }
    }
  }

  protected chartLegend (detailed: boolean, enable: boolean|undefined): LegendOptions {
    const genericLegend = {
      enabled: enable ?? false,
      itemHoverStyle: { color: textWhite },
      itemStyle: {
        color: textPrim,
        lineHeight: !detailed ? '15px' : '15px',
        fontFamily: this.DefaultFont,
        fontSize: detailed ? '12px' : '10px',
        fontWeight: !detailed ? 'lighter' : 'bold'
      },
      maxHeight: detailed ? 60 : 40,
      itemWidth: detailed ? 210 : undefined
    }
    return genericLegend
  }

  private getXAxisFormatter (firstValue: string|undefined) : (value: string) => string {
    const regex = /^((\d{4}-\d{2}-\d{2})|(\d{4}\/\d{2}\/\d{2})|(\d{2}-\d{2}-\d{4})|(\d{2}\/\d{2}\/\d{4}))/ // YYYY-MM-DD or DD-MM-YYYY

    if (firstValue !== undefined && regex.test(firstValue)) {
      return (value: string) => { return FormatService.formatDate(value) }
    }

    return (value: string) => { return value }
  }

  // eslint-disable-next-line
  protected xAxis (XAxis: string[], detailed: boolean, title?: string): xAxisOptions | any {
    const noPadding =
    detailed
      ? {}
      : {
          minPadding: 0,
          maxPadding: 0
        }

    const formatter = this.getXAxisFormatter(XAxis[0])
    const formattedXAxis = XAxis.map((value) => formatter(value))

    return {
      ...noPadding,
      categories: formattedXAxis,
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      lineWidth: detailed ? 1 : 0,
      tickPixelInterval: 20,
      labels: {
        allowOverlap: true,
        enabled: detailed,
        style: { color: textAlt }
      },
      crosshair: { width: 1 }
    }
  }

  protected createYOptions (series: Partial<SerieOptions>[], categories: ChartCategoryModel[], detailed: boolean): Partial<yAxisOptions>[] {
    const yAxisOptions = []
    let yAxisOptionIndex = 0
    for (const serie of series) {
      const yAxisOption = {
        title: { enabled: false },
        lineWidth: 0,
        minorGridLineWidth: detailed ? 1 : 0,
        gridLineWidth: (detailed && categories.length > 1) || (detailed && categories.length === 1 && yAxisOptionIndex === 0) ? 1 : 0,
        gridLineColor: 'rgba(255,255,255,0.2)',
        labels: {
          enabled: detailed && categories.length > 0,
          format: categories.length > 1 ? `{value} ${serie.name}` : '',
          showLastLabel: true,
          style: { color: textAlt },
          autoRotation: true
        },
        type: serie.type,
        opposite: categories.length > 1 ? yAxisOptionIndex !== 0 : false,
        yAxis: (categories.length === 1) ? 0 : yAxisOptionIndex,
        stackedLabels: {
          enabled: true
        },
        min: 0,
        minRange: 2
      }
      yAxisOptions.push(yAxisOption)
      yAxisOptionIndex++
    }
    return yAxisOptions
  }

  protected exporting (detailed: boolean): ExportingOptions {
    const buttonList = ['viewFullscreen', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
    return {
      enabled: detailed,
      style: { fontFamily: this.DefaultFont },
      chartOptions: {
        chart: {
          backgroundColor: bg2,
          plotBackgroundImage: ''
        }
      },
      buttons: {
        contextButton: {
          menuItems: buttonList
        }
      }
    }
  }

  protected navigation (): NavigationOptions {
    return {
      buttonOptions: {
        theme: {
          backgroundColor: 'transparent',
          fill: 'transparent',
          states: {
            hover: {
              stroke: textGray400,
              fill: 'transparent'
            },
            select: {
              stroke: textGray400,
              fill: 'transparent'
            }
          },
          fontFamily: this.DefaultFont
        }
      }
    }
  }

  // protected defineMargins (detailed: boolean, previewType?: string): number[] | undefined {
  //   return (detailed || (!detailed && pieCharts.includes(chartTypes[this.Type as keyof typeof chartTypes]) && previewType !== 'ChartAndKPI'))
  //     ? undefined
  //     : [0, 0, 0, 0]
  // }
}
