import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20.005",
  viewBox: "0 0 20 20.005"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      id: "Icon_ionic-ios-search",
      "data-name": "Icon ionic-ios-search",
      d: "M24.265,23.05,18.7,17.436a7.927,7.927,0,1,0-1.2,1.219l5.526,5.578a.856.856,0,0,0,1.208.031A.862.862,0,0,0,24.265,23.05ZM12.474,18.722A6.259,6.259,0,1,1,16.9,16.889,6.221,6.221,0,0,1,12.474,18.722Z",
      transform: "translate(-4.5 -4.493)",
      fill: "#a9b8db"
    }, null, -1)
  ])))
}