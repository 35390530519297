import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  "data-element-id": "mapLayersPopover",
  "data-sticky-parent": "stickyMenuRight",
  class: "p-1 dark:bg-slate-800 absolute rounded-md drop-shadow-md bg-white/50",
  style: {"box-shadow":"0 0 10px rgba(169,184,219,.4)"}
}
const _hoisted_2 = { class: "flex items-center pl-2.5 py-2.5 border-b border-gray-700" }
const _hoisted_3 = { class: "mt-2.5 flex" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  class: "text-slate-300 text-sm capitalize inline-block text-center",
  style: {"background":"rgba(0,0,0,.4)","width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_sticky = _resolveDirective("sticky")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "grow text-xl dark:text-white font-bold" }, "Map layers", -1)),
      _createVNode(_component_router_link, {
        to: { query :  { ..._ctx.$route.query, popover: undefined}  },
        class: "text-white"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("span", { class: "fa-stack" }, [
            _createElementVNode("i", { class: "fa-solid text-slate-600 fa-circle fa-circle fa-stack-2x" }),
            _createElementVNode("i", {
              class: "fa-regular fa-circle-xmark fa-stack-1x dark:text-white",
              style: {"font-size":"1.2em"}
            })
          ], -1)
        ])),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (item, index) => {
        return (_openBlock(), _createElementBlock("a", {
          style: _normalizeStyle({'background-image': item.img}),
          key: index,
          onClick: _withModifiers(($event: any) => (_ctx.onChangeUrl(item.name)), ["prevent"]),
          class: "card border-2 dark:border-slate-800 hover:border-blue-600 dark:hover:border-custom-blue border-white inline-block h-16 relative bg-cover mx-1 w-32 rounded-md flex items-end justify-center"
        }, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(item.title), 1)
        ], 12, _hoisted_4))
      }), 128))
    ])
  ])), [
    [_directive_sticky]
  ])
}