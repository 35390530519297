import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "py-3 lg:py-6" }
const _hoisted_2 = { class: "grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4 body body-1" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = { class: "font-bold" }
const _hoisted_8 = { class: "font-bold" }
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "font-bold" }
const _hoisted_12 = { class: "body-1 text-semantic-negative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_DepartmentSelect = _resolveComponent("DepartmentSelect")!
  const _component_SectorsSelect = _resolveComponent("SectorsSelect")!
  const _component_LogoutIcon = _resolveComponent("LogoutIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageTitle, {
      title: _ctx.$t('profile.title'),
      subtitle: _ctx.$t('profile.subtitle')
    }, null, 8, ["title", "subtitle"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "p-3 pl-0 w-32 min-w-[8rem]" }, "Department", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_DepartmentSelect, { onDepartmentChanged: _ctx.departmentChanged }, null, 8, ["onDepartmentChanged"])
              ])
            ]),
            _createElementVNode("table", null, [
              _createElementVNode("tr", null, [
                _cache[2] || (_cache[2] = _createElementVNode("td", { class: "p-3 pl-0" }, "First Name", -1)),
                _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.user.firstName), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[3] || (_cache[3] = _createElementVNode("td", { class: "p-3 pl-0" }, "Last Name", -1)),
                _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.user.lastName), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[4] || (_cache[4] = _createElementVNode("td", { class: "p-3 pl-0" }, "Department", -1)),
                _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.department), 1)
              ]),
              _createElementVNode("tr", null, [
                _cache[5] || (_cache[5] = _createElementVNode("td", { class: "p-3 pl-0" }, "Email", -1)),
                _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.user.email), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "p-3 pl-0 w-32 min-w-[8rem]" }, "Activity Sectors", -1)),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_SectorsSelect, { onChangeSectors: _ctx.changeSectors }, null, 8, ["onChangeSectors"])
              ])
            ]),
            _createElementVNode("table", null, [
              _createElementVNode("tr", _hoisted_12, [
                _createElementVNode("td", {
                  class: "p-3 cursor-pointer flex items-center",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
                }, [
                  _cache[7] || (_cache[7] = _createElementVNode("span", null, "Log out", -1)),
                  _createVNode(_component_LogoutIcon, { class: "ml-1" })
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _cache[8] || (_cache[8] = _createElementVNode("hr", { class: "my-6 border-t-gray-800" }, null, -1))
  ]))
}