import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex mr-2 w-5 flex-col h-7 justify-center" }
const _hoisted_2 = { class: "body body-2 text-ellipsis relative table-cell align-middle h-7" }

import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import ColoursService from '@/core/services/colours.service'


export default /*@__PURE__*/_defineComponent({
  __name: 'card.empty',
  props: {
    title: {}
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GenericCardShell, {
    class: "min-h-24 flex relative h-[102px] p-4 w-fit min-w-[14rem]",
    style: {"min-width":"14rem"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "w-3 h-3 rounded-full",
          style: _normalizeStyle(`background-color: ${_unref(ColoursService).getColour(_ctx.title)}`)
        }, null, 4)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("h4", { class: "my-1.5" }, [
          _createElementVNode("span", null, "Unavailable")
        ], -1))
      ])
    ]),
    _: 1
  }))
}
}

})