import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "px-6 min-h-[6rem]" }
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardSmallCard = _resolveComponent("DashboardSmallCard")!
  const _component_FavouriteButton = _resolveComponent("FavouriteButton")!
  const _component_GenericCardShell = _resolveComponent("GenericCardShell")!

  return (_openBlock(), _createBlock(_component_GenericCardShell, {
    class: "flex flex-col sm:flex-row min-h-45 w-full p-6 items-center cursor-pointer",
    onClick: _ctx.goToDashboard
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DashboardSmallCard, {
        id: _ctx.id,
        title: _ctx.title,
        previewImageUrl: _ctx.previewImageUrl,
        loading: _ctx.loading
      }, null, 8, ["id", "title", "previewImageUrl", "loading"]),
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          class: _normalizeClass(["px-6 text-lg font-bold text-left dashboard-card--title uppercase", { 'skeleton mx-6 mb-0.5': _ctx.loading }])
        }, _toDisplayString(_ctx.title), 3),
        _createElementVNode("div", {
          class: _normalizeClass(["mb-4 px-6 text-md text-left", { 'skeleton mx-6': _ctx.loading }])
        }, [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.service), 1))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.description), 1))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_FavouriteButton, {
          onChangeFavStatus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isFav = !_ctx.isFav)),
          isFav: _ctx.isFav,
          "stroke-fav": '#0C111C',
          stroke: 'white',
          "fill-fav": '#0C111C',
          fill: 'none'
        }, null, 8, ["isFav"])
      ])
    ]),
    _: 1
  }, 8, ["onClick"]))
}