import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex mr-2 w-5 flex-col h-7 justify-center" }
const _hoisted_2 = { class: "body body-2 text-ellipsis relative table-cell align-middle h-7" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "my-1.5" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "body-alt body-2"
}

import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import ColoursService from '@/core/services/colours.service'
import FormatService from '@/core/services/format.service'


export default /*@__PURE__*/_defineComponent({
  __name: 'card',
  props: {
    type: {},
    label: {},
    title: {},
    value: {},
    unit: {},
    description: {}
  },
  setup(__props: any) {

const props = __props


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GenericCardShell, {
    class: "min-h-24 flex relative h-[102px] p-4 w-fit min-w-[14rem]",
    style: {"min-width":"14rem"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "w-3 h-3 rounded-full",
          style: _normalizeStyle(`background-color: ${_unref(ColoursService).getColour(_ctx.label ?? _ctx.title)}`)
        }, null, 4)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          ((_ctx.label ?? _ctx.title))
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.label ?? _ctx.title), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("h4", _hoisted_4, [
          (_ctx.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(FormatService).formatNumber(+_ctx.value)) + " " + _toDisplayString(_ctx.unit), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_6, "Unavailable"))
        ]),
        (_ctx.description)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.description), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})