
import ReportsListMenuShell from '@/components/buttons/ReportsListMenuShell.vue'
import ReportListByServiceCategory from '@/components/reports/categories/ServiceCategoryList.report.vue'
import ReportListByServiceCategoryLoading from '@/components/reports/categories/ReportListByServiceCategory.loading.vue'
import ReportManager from '@/core/managers/report.manager'
import * as FleetManager from '@/core/managers/fleet.manager'
import { ReportServiceCategory } from '@/core/model/report.model'
import { defineComponent, Ref, ref } from 'vue'
import { useToast } from 'vue-toastification'
import { AssetApiModel } from '@/core/model/fleet.model'

export default defineComponent({
  components: {
    ReportsListMenuShell,
    ReportListByServiceCategoryLoading,
    ReportListByServiceCategory
  },
  name: 'Service.detail.vue',
  setup () {
    const toast = useToast()
    const error = ref('')
    const categories: Ref<ReportServiceCategory[]> = ref([])
    // const assetOnlyServices: Ref<ReportServiceCategory[]> = ref([])
    const loading = ref(true)
    const assetCategories: Ref<{id: number, name: string}[]> = ref([])
    const assets: Ref<AssetApiModel[]> = ref([])
    return {
      toast,
      error,
      categories,
      loading,
      assetCategories,
      assets
    }
  },
  async created () {
    const assetCategoriesResponse = await FleetManager.Manager.categories()
    if (!assetCategoriesResponse.isError && assetCategoriesResponse.data) {
      this.assetCategories = assetCategoriesResponse.data.categories
    } else {
      this.error = `${this.$t('fleet.errorCategory')}${assetCategoriesResponse.Message}`
      this.toast.error(this.error)
    }
    await this.listAllAssets()

    // const assetReportResponse = await ReportManager.list(true)
    const serviceReportResponse = await ReportManager.list()

    if (serviceReportResponse.isError) { // || assetReportResponse.isError) {
      this.error = this.$t('allReports.error', {
        error: serviceReportResponse.Message
      })
      this.toast.error(this.error)
    }

    if (serviceReportResponse.data) {
      const categories = serviceReportResponse.data.serviceCategories
      this.categories = categories.map(s => {
        s.groups.forEach(g => { g.active = true })
        return ({ ...s, active: true })
      })
    }

    // if (assetReportResponse.data) {
    //   const categories = assetReportResponse.data.serviceCategories
    //   this.assetOnlyServices = categories.map(s => {
    //     s.groups.forEach(g => { g.active = true })
    //     return ({ ...s, active: true })
    //   })
    // }

    this.loading = false
  },
  mounted () {
    this.$router.push({ query: undefined })
  },
  methods: {
    async listAllAssets () : Promise<void> {
      this.assets = []
      for (const category of this.assetCategories) {
        await this.buildAssetList(category.id)
      }
    },
    async buildAssetList (id: number, index = 0) : Promise<void> {
      const response = await FleetManager.Manager.assets(id, index)
      if (response.isError || !response.data) return

      this.assets = this.assets.concat(response.data.assets)
      if (!response.data.hasNextPage) return
      await this.buildAssetList(id, index + 1)
    },
    rotateArrow () {
      const arrowContainer = document.querySelectorAll(
        '.vue-treeselect__control-arrow-container'
      )
      arrowContainer[0].classList.toggle('rotate-x-180')
    }
  }
})
