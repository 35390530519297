import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "Capa_1",
  "enable-background": "new 0 0 512 512",
  height: "512",
  viewBox: "0 0 512 512",
  width: "512",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", null, [
      _createElementVNode("path", { d: "m316 136h51.716l-71.716 71.716-80-80-134.142 134.142 28.284 28.284 105.858-105.858 80 80 100-100v51.716h40v-120h-120z" }),
      _createElementVNode("path", { d: "m452 16h-392c-33.084 0-60 26.916-60 60v240c0 33.084 26.916 60 60 60h176v80h-80v40h200v-40h-80v-80h176c33.084 0 60-26.916 60-60v-240c0-33.084-26.916-60-60-60zm20 300c0 11.028-8.972 20-20 20h-392c-11.028 0-20-8.972-20-20v-240c0-11.028 8.972-20 20-20h392c11.028 0 20 8.972 20 20z" })
    ], -1)
  ])))
}