import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { RouteLocationRaw } from 'vue-router'


export default /*@__PURE__*/_defineComponent({
  __name: 'GenericCardShell',
  props: {
    link: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.link ? 'router-link' : 'div'), {
    to: _ctx.link,
    class: "generic-card rounded-xl text-gray-200 relative bg-bg-2"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["to"]))
}
}

})