import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  role: "alert",
  "aria-live": "assertive",
  class: "bg-green-100 border border-green-500 py-2 px-3 rounded-md flex items-center",
  "aria-atomic": "true",
  style: {"z-index":"999","position":"fixed","right":"50px","bottom":"20px"}
}
const _hoisted_2 = { class: "text-green-600 text-xs" }
const _hoisted_3 = {
  key: 0,
  role: "alert",
  "aria-live": "assertive",
  class: "bg-red-100 border border-red-500 py-2 px-3 rounded-md flex items-center",
  "aria-atomic": "true",
  style: {"z-index":"999","position":"fixed","right":"50px","bottom":"20px"}
}
const _hoisted_4 = { class: "text-red-600 text-xs" }
const _hoisted_5 = {
  key: 0,
  role: "alert",
  "aria-live": "assertive",
  class: "bg-red-100 border border-red-500 py-2 px-3 rounded-md flex items-center",
  "aria-atomic": "true",
  style: {"z-index":"999","position":"absolute","right":"50px","bottom":"20px"}
}
const _hoisted_6 = { class: "text-red-600 text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.GStore.msgSuccess !=='')
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _cache[0] || (_cache[0] = _createElementVNode("i", { class: "fa-duotone fa-check me-2 text-green-600 fa-lg mr-2" }, null, -1)),
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.GStore.msgSuccess), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.GStore.msgError !=='')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa-duotone fa-warning me-2 text-red-600 fa-lg" }, null, -1)),
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.GStore.msgError), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.GStore.mapError !=='')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa-duotone fa-warning me-2 text-red-600 fa-lg" }, null, -1)),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.GStore.mapError), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}