import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  id: "Icon_-_Dashboard",
  "data-name": "Icon - Dashboard",
  xmlns: "http://www.w3.org/2000/svg",
  width: "30",
  height: "30",
  viewBox: "0 0 30 30",
  fill: "currentColor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g id=\"Groupe_5551\" data-name=\"Groupe 5551\" transform=\"translate(0 0)\"><g id=\"Layer_15\" data-name=\"Layer 15\"><path id=\"Tracé_8225\" data-name=\"Tracé 8225\" d=\"M29.643,32.857H6.071a3.214,3.214,0,0,1-3.214-3.214v-15a3.214,3.214,0,0,1,3.214-3.214H29.643a3.214,3.214,0,0,1,3.214,3.214v15A3.214,3.214,0,0,1,29.643,32.857ZM6.071,13.571A1.071,1.071,0,0,0,5,14.643v15a1.071,1.071,0,0,0,1.071,1.071H29.643a1.071,1.071,0,0,0,1.071-1.071v-15a1.071,1.071,0,0,0-1.071-1.071Zm22.5-9.643A1.071,1.071,0,0,0,27.5,2.857H8.214A1.071,1.071,0,1,0,8.214,5H27.5A1.071,1.071,0,0,0,28.571,3.929Zm2.143,4.286a1.071,1.071,0,0,0-1.071-1.071H6.071a1.071,1.071,0,0,0,0,2.143H29.643A1.071,1.071,0,0,0,30.714,8.214Z\" transform=\"translate(-2.857 -2.857)\"></path></g></g><g id=\"Groupe_739\" data-name=\"Groupe 739\" transform=\"translate(10.714 13.929)\"><rect id=\"Rectangle_995\" data-name=\"Rectangle 995\" width=\"2.089\" height=\"5.223\" rx=\"1.045\" transform=\"translate(0 5.223)\"></rect><rect id=\"Rectangle_996\" data-name=\"Rectangle 996\" width=\"2.089\" height=\"10.446\" rx=\"1.045\" transform=\"translate(3.134)\"></rect><rect id=\"Rectangle_997\" data-name=\"Rectangle 997\" width=\"2.089\" height=\"7.835\" rx=\"1.045\" transform=\"translate(6.268 2.612)\"></rect></g>", 2)
  ])))
}