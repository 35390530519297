import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "py-3 px-2.5 hover:bg-bg-3 rounded-xl" }
const _hoisted_2 = { class: "py-3 px-2.5 w-3" }
const _hoisted_3 = {
  key: 0,
  class: "py-3 px-2.5 w-3"
}
const _hoisted_4 = { class: "py-3 pl-2.5" }
const _hoisted_5 = { style: {"display":"none"} }
const _hoisted_6 = { class: "py-3 pr-2.5 flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoaderIcon = _resolveComponent("LoaderIcon")!
  const _component_DownloadIcon = _resolveComponent("DownloadIcon")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.item.fileFormat?.toLowerCase() || 'pdf'), { class: "w-6 h-auto stroke-semantic-activate fill-semantic-activate" }))
    ]),
    (_ctx.item.period)
      ? (_openBlock(), _createElementBlock("td", _hoisted_3, [
          _createElementVNode("span", {
            class: _normalizeClass([_ctx.periodClass(_ctx.item.period), "px-2 py-1 border border-2 rounded-xl text-sm"])
          }, _toDisplayString(_ctx.item.period), 3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("td", _hoisted_4, [
      _createTextVNode(_toDisplayString(_ctx.item.name) + " - " + _toDisplayString(_ctx.formatDate(_ctx.item.instanceDate)), 1),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.item.tinyGuid), 1)
    ]),
    _createElementVNode("td", _hoisted_6, [
      (_ctx.isInternalUser)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onDelete(_ctx.reportId,_ctx.item.id)))
          }, _cache[1] || (_cache[1] = [
            _createElementVNode("i", {
              class: "fa fa-circle-xmark text-blue-400",
              style: {"font-size":"1.4rem"}
            }, null, -1)
          ])))
        : _createCommentVNode("", true),
      _createElementVNode("span", {
        class: _normalizeClass([_ctx.item.IsDownloading ? '' : 'cursor-pointer', "h-10 w-10 bg-bg-2 rounded-full body-1 text-text-alt font-bold flex items-center justify-center"])
      }, [
        (_ctx.item.IsDownloading)
          ? (_openBlock(), _createBlock(_component_LoaderIcon, {
              key: 0,
              class: "stroke-white"
            }))
          : (_openBlock(), _createBlock(_component_DownloadIcon, {
              key: 1,
              onClick: _ctx.download,
              class: "stroke-white"
            }, null, 8, ["onClick"]))
      ], 2)
    ])
  ]))
}