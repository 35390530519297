import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  version: "1.1",
  id: "Capa_1",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 512 512",
  style: {"enable-background":"new 0 0 512 512"},
  "xml:space": "preserve"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", null, [
      _createElementVNode("g", null, [
        _createElementVNode("path", { d: "M452,36H60C26.916,36,0,62.916,0,96v240c0,33.084,26.916,60,60,60h176v40H132v40h248v-40H276v-40h176 c33.084,0,60-26.916,60-60V96C512,62.916,485.084,36,452,36z M472,336c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V96 c0-11.028,8.972-20,20-20h392c11.028,0,20,8.972,20,20V336z" })
      ])
    ], -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1),
    _createElementVNode("g", null, null, -1)
  ])))
}