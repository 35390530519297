import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-6 h-100 select-none" }
const _hoisted_2 = { class: "home-grid" }
const _hoisted_3 = ["poster", "id"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src", "alt", "id"]
const _hoisted_6 = { class: "absolute top-6 left-6 right-6 p-2 bg-bg-2 bg-opacity-50 b w-fit rounded-lg" }
const _hoisted_7 = { class: "italic font-extrabold !text-lg sm:!text-3xl" }
const _hoisted_8 = { class: "text-text-prim text-sm sm:text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_CardButton = _resolveComponent("CardButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageTitle, {
      title: _ctx.$t('auth.welcome')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page, i) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(page.sameApp ? 'router-link' : 'a'), {
          to: { name: page.pageName, query: page.query },
          href: page.pageName,
          target: page.sameApp ? '' : '_blank',
          class: "home-grid--item",
          key: i,
          onMouseenter: ($event: any) => (page.hover = true),
          onMouseleave: ($event: any) => (page.hover = false)
        }, {
          default: _withCtx(() => [
            (page.hover)
              ? (_openBlock(), _createElementBlock("video", {
                  key: 0,
                  autoplay: "",
                  muted: "",
                  loop: "",
                  playsinline: "",
                  poster: page.poster,
                  class: "w-full h-full",
                  id: `video-${page.title}`
                }, [
                  _createElementVNode("source", {
                    src: page.video,
                    type: "video/mp4"
                  }, null, 8, _hoisted_4)
                ], 8, _hoisted_3))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: page.poster,
                  alt: page.title,
                  id: `img-${page.title}`
                }, null, 8, _hoisted_5)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h1", _hoisted_7, _toDisplayString(page.title), 1),
              _createElementVNode("p", _hoisted_8, _toDisplayString(page.subtitle), 1)
            ]),
            _createVNode(_component_CardButton, { "btn-text": 'Open' })
          ]),
          _: 2
        }, 1064, ["to", "href", "target", "onMouseenter", "onMouseleave"]))
      }), 128))
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("hr", { class: "mt-20 mb-10 border-t-gray-800" }, null, -1))
  ]))
}