import { CategoriesListModel } from '@/core/model/category.model'
import { ServiceDetailsModel } from '@/core/model/service.model'
import { ApiResult, http } from '@/core/services/request.service'

export const Manager = {
  list (): Promise<ApiResult<CategoriesListModel>> {
    return http.request<CategoriesListModel>('get', 'Services')
      .then((rep: ApiResult<CategoriesListModel>) => rep)
      .catch(err => err)
  },
  detail (id: number): Promise<ApiResult<ServiceDetailsModel>> {
    return http.request<ServiceDetailsModel>('get', `Services/${id}`)
      .then((rep: ApiResult<ServiceDetailsModel>) => rep)
      .catch(err => err)
  }
}
