import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-6 pb-4 mb-4" }
const _hoisted_2 = { class: "-mx-6 border-b mb-4" }

import { computed, onMounted, ref } from 'vue'
import { useCategories } from '@/components/analytics/services/useCategories'
import subPart from '@/components/analytics/services/sub-part.vue'
import { AssetApiModel } from '@/core/model/fleet.model'
import { ReportInfo } from '@/core/model/report.model'
import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import ReportGroupHeader from '@/components/reports/categories/services/reports/headers/ReportGroupHeader.vue'
import ServiceReport from '@/components/reports/categories/services/reports/ServiceReport.vue'
import AssetReport from '@/components/reports/categories/services/reports/AssetReport.vue'
import * as FleetManager from '@/core/managers/fleet.manager'


export default /*@__PURE__*/_defineComponent({
  __name: 'report-section',
  props: {
    analyticsId: {},
    reports: {},
    subTitle: {}
  },
  setup(__props: any) {

const props = __props
const assets = ref<AssetApiModel[]>([])
const { categories, isLoading, error } = useCategories()
const currentCategory = computed(() => categories.value.find(c => c.services.findIndex(s => s.id === props.analyticsId) > -1))

onMounted(async () => {
  if (props.reports.findIndex(r => r.isAssetRelated) > -1) {
    let builtAssets = [] as AssetApiModel[]
    const response = await FleetManager.Manager.categories()
    for (const c of response.data?.categories ?? []) {
      builtAssets = builtAssets.concat(await buildAssetList(c.id))
    }

    assets.value = builtAssets
  }
})

async function buildAssetList (id: number, index = 0) : Promise<AssetApiModel[]> {
  const response = await FleetManager.Manager.assets(id, index, 100)

  if (response.isError || !response.data) return []

  const assets = response.data.assets
  if (!response.data.hasNextPage) return assets

  return assets.concat(await buildAssetList(id, index + 1))
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(subPart, { part: 'report' }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reports, (report, j) => {
        return (_openBlock(), _createBlock(GenericCardShell, {
          key: j,
          class: "mb-4"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(ReportGroupHeader, {
                  id: report.id,
                  title: report.title,
                  "is-asset-report": report.isAssetRelated,
                  subtitle: currentCategory.value ? `${currentCategory.value} | ${_ctx.subTitle}` : `${_ctx.subTitle}`
                }, null, 8, ["id", "title", "is-asset-report", "subtitle"])
              ]),
              (!report.isAssetRelated)
                ? (_openBlock(), _createBlock(ServiceReport, {
                    key: 0,
                    id: report.id,
                    "report-title": report.title
                  }, null, 8, ["id", "report-title"]))
                : (assets.value.length > 0)
                  ? (_openBlock(), _createBlock(AssetReport, {
                      key: 1,
                      id: report.id,
                      "report-title": report.title,
                      assets: assets.value,
                      "is-in-reports": true
                    }, null, 8, ["id", "report-title", "assets"]))
                  : _createCommentVNode("", true)
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}
}

})