import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "title",
  class: "flex items-center mb-9"
}
const _hoisted_2 = { class: "ml-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftArrowIcon = _resolveComponent("LeftArrowIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goTo && _ctx.goTo(...args))),
      class: "p-2.5 bg-bg-3 rounded-full"
    }, [
      _createVNode(_component_LeftArrowIcon, { class: "h-5 w-5 stroke-gray-100" })
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.small ? 'h3': 'h1'), {
        class: _normalizeClass([{ skeleton__title: _ctx.loading }, "!leading-7.5"])
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(_ctx.error || _ctx.title), 1)
        ]),
        _: 1
      }, 8, ["class"]))
    ])
  ]))
}