import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "relative h-full",
  id: "stickyMenuRight"
}
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "absolute top-2 right-2 rounded-sm drop-shadow-sm border-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vessel_detail = _resolveComponent("vessel-detail")!
  const _component_menu_right = _resolveComponent("menu-right")!
  const _component_toaster = _resolveComponent("toaster")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$route.query.panelDetail)
      ? (_openBlock(), _createBlock(_component_vessel_detail, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      id: _ctx.mapId,
      class: "h-full z-0"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.mapInitialized)
        ? (_openBlock(), _createBlock(_component_menu_right, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", {
      id: "end-of-body",
      class: "z-2"
    }, null, -1)),
    _createVNode(_component_toaster),
    _createVNode(_component_loader)
  ]))
}