import { BaseChartGenerator } from '@/core/charts/baseChart.service'
import { IChartGenerator } from '@/core/charts/ichartgenerator.model'
import { ChartOptionsModel } from '@/core/model/chart.model'
import ColoursService, { textAlt, textPrim } from '@/core/services/colours.service'
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge'

highchartsMore(Highcharts)
solidGauge(Highcharts)

class GaugeChart extends BaseChartGenerator implements IChartGenerator {
  public createGaugeFromKpi (title: string, value: string, unit: string, type: string, detailed: boolean): Partial<ChartOptionsModel> {
    const gaugeOptions = {
      chart: {
        type: type === 'SOLID_GAUGE' ? 'solidgauge' : 'gauge',
        backgroundColor: 'transparent',
        height: type === 'SOLID_GAUGE' ? '125px' : '195px',
        margin: [0, 0, 0, 0]
      },
      title: false,
      pane: {
        startAngle: type === 'SOLID_GAUGE' ? 0 : -90,
        endAngle: type === 'SOLID_GAUGE' ? 360 : 90,
        background: {
          backgroundColor: `${ColoursService.getColour(title)}4D`,
          innerRadius: type === 'SOLID_GAUGE' ? '60%' : '95%',
          outerRadius: '100%',
          shape: type === 'SOLID_GAUGE' ? 'circle' : 'arc',
          borderWidth: 0
        }
      },
      exporting: { enabled: false },
      tooltip: this.tooltip(detailed, true),
      plotOptions: {
        dataLabels: {
          allowOverlap: true
        },
        solidgauge: {
          dataLabels: {
            y: -16,
            x: 0,
            style: { fontSize: '16px', color: textAlt },
            z: 6
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true
        },
        gauge: {
          dial: {
            backgroundColor: '#fff',
            baseWidth: 4,
            baseLength: '0%',
            rearLength: '0%'
          },
          pivot: {
            backgroundColor: '#fff',
            radius: 1
          },
          dataLabels: {
            y: 10,
            x: 0,
            style: { fontSize: '12px', color: textAlt },
            z: 6
          }
        }
      },
      yAxis: {
        min: 0,
        max: type === 'SOLID_GAUGE' ? 100 : 20,
        title: {
          text: type === 'SOLID_GAUGE' ? '' : type === 'GAUGE' ? 'Kn' : unit,
          y: 1,
          style: { fontSize: '10px', color: textAlt, fontWeight: 'bold' }
        },
        stops: type === 'SOLID_GAUGE'
          ? undefined
          : [
              [0.33, '#B9E7E2'],
              [0.66, '#339C8F'],
              [1.0, '#22685F']
            ],
        lineWidth: 0,
        tickWidth: type === 'SOLID_GAUGE' ? 0 : 1,
        minorTickWidth: type === 'SOLID_GAUGE' ? 0 : 1,
        minorTickInterval: type === 'SOLID_GAUGE' ? null : 5,
        tickAmount: 0,
        labels: {
          enabled: false
        },
        plotBands: type === 'SOLID_GAUGE'
          ? { enabled: false }
          : [{
              from: 0,
              to: 6.6,
              color: '#B9E7E2',
              thickness: '10%'
            }, {
              from: 6.6,
              to: 13.2,
              color: '#339C8F',
              thickness: '10%'
            }, {
              from: 13.1,
              to: 20,
              color: '#22685F',
              thickness: '10%'
            }]
      },
      credits: {
        enabled: false
      },
      series: [{
        name: title,
        data: [{ color: ColoursService.getColour(title), y: parseFloat(value) }],
        dataLabels: {
          format: unit ? `{y} ${unit}` : '{y}',
          borderWidth: 0,
          color: textPrim,
          style: {
            fontFamily: this.DefaultFont,
            textOutline: 'none'
          },
          enabled: type === 'SOLID_GAUGE'
        },
        tooltip: {
          valueSuffix: ` ${unit}`
        }
      }]
    }
    return gaugeOptions
  }
}

export default new GaugeChart()
