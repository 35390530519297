import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "Capa_1",
  "enable-background": "new 0 0 512 512",
  height: "512",
  viewBox: "0 0 512 512",
  width: "512",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m256 40c-141.484 0-256 114.497-256 256 0 62.371 22.704 122.471 63.928 169.227 3.797 4.306 9.261 6.773 15.002 6.773h354.14c5.741 0 11.205-2.467 15.001-6.773 41.225-46.756 63.929-106.856 63.929-169.227 0-141.483-114.497-256-256-256zm167.798 392h-335.596c-26.942-33.209-43.329-73.569-47.266-116h19.064c11.046 0 20-8.954 20-20s-8.954-20-20-20h-19.079c3.971-43.535 20.877-84.349 48.853-117.942l13.492 13.492c7.81 7.81 20.473 7.81 28.284-.001s7.811-20.474 0-28.284l-13.491-13.491c33.591-27.976 74.406-44.882 117.941-48.853v19.079c0 11.046 8.954 20 20 20s20-8.954 20-20v-19.079c103.361 9.428 185.652 91.733 195.079 195.079h-19.079c-11.046 0-20 8.954-20 20s8.954 20 20 20h19.064c-3.937 42.431-20.325 82.791-47.266 116zm-71.683-260.399-70.263 70.263c-7.833-3.756-16.601-5.864-25.852-5.864-33.084 0-60 26.916-60 60s26.916 60 60 60 60-26.916 60-60c0-9.251-2.108-18.019-5.863-25.852l70.262-70.262c7.811-7.811 7.811-20.475 0-28.285s-20.474-7.811-28.284 0zm-96.115 144.399c-11.028 0-20-8.972-20-20s8.972-20 20-20 20 8.972 20 20-8.972 20-20 20z" }, null, -1)
  ])))
}