import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "mt-6 h-100" }
const _hoisted_2 = {
  class: "mt-8",
  ref: "categoryWrapper"
}

import { nextTick, onMounted, ref, watch } from 'vue'
import { useCategories } from '@/components/analytics/services/useCategories'
import CategoryListItem from '@/components/analytics/services/category-list-item.vue'
import CategoryListTags from '@/components/analytics/services/category-list-tags-header.vue'
import PageTitle from '@/components/titles/PageTitle.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'Analytics.list',
  setup(__props) {

const { categories, isLoading, error } = useCategories()

const visibleCategories = ref<boolean[]>([])

onMounted(async () => {
  if (isLoading.value) {
    watch(categories, () => {
      visibleCategories.value = categories.value.map(_ => true)
    }, { once: true })
  } else {
    visibleCategories.value = categories.value.map(_ => true)
    await nextTick()
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(PageTitle, {
      title: _ctx.$t('analytics.title'),
      subtitle: _ctx.$t('analytics.subtitle')
    }, null, 8, ["title", "subtitle"]),
    _createVNode(CategoryListTags, {
      categories: _unref(categories),
      isLoading: _unref(isLoading),
      error: _unref(error),
      visibleCategories: visibleCategories.value,
      "onUpdate:visibleCategories": _cache[0] || (_cache[0] = ($event: any) => ((visibleCategories).value = $event))
    }, null, 8, ["categories", "isLoading", "error", "visibleCategories"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_TransitionGroup, { name: "fade" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category, index) => {
            return _withDirectives((_openBlock(), _createBlock(CategoryListItem, {
              key: index,
              category: category
            }, null, 8, ["category"])), [
              [_vShow, visibleCategories.value[index]]
            ])
          }), 128))
        ]),
        _: 1
      })
    ], 512)
  ]))
}
}

})