import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-6 pb-4" }
const _hoisted_2 = { class: "-mx-6 border-b" }
const _hoisted_3 = {
  key: 0,
  class: "flex items-center justify-center p-2 pb-4"
}
const _hoisted_4 = {
  key: 1,
  class: "p-6 pt-4 mb-4"
}
const _hoisted_5 = { class: "relative block" }
const _hoisted_6 = { class: "relative block" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "relative block" }
const _hoisted_9 = { class: "absolute inset-y-0 left-0 flex items-center pl-2" }
const _hoisted_10 = { for: "file-input" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = {
  key: 1,
  class: "preview-file-container mt-10"
}
const _hoisted_14 = { class: "ml-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormHeader = _resolveComponent("FormHeader")!
  const _component_LoaderIcon = _resolveComponent("LoaderIcon")!
  const _component_CalendarIcon = _resolveComponent("CalendarIcon")!
  const _component_DropZone = _resolveComponent("DropZone")!
  const _component_FilePreview = _resolveComponent("FilePreview")!
  const _component_UploadIcon = _resolveComponent("UploadIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FormHeader, {
          title: _ctx.title,
          ClientDepartment: _ctx.clientDepartmentContext,
          onClose: _ctx.close
        }, null, 8, ["title", "ClientDepartment", "onClose"])
      ])
    ]),
    (_ctx.uploading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_LoaderIcon)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, [
            _cache[5] || (_cache[5] = _createElementVNode("span", null, "Title:", -1)),
            _withDirectives(_createElementVNode("input", {
              class: "block bg-bg-0 w-full rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-text-alt focus:ring-text-alt focus:ring-1 sm:text-sm text-text-alt body-2 !leading-4 font-bold",
              placeholder: "Title",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
              type: "Text",
              name: "Format"
            }, null, 512), [
              [_vModelText, _ctx.name]
            ])
          ]),
          _createElementVNode("label", _hoisted_6, [
            _cache[7] || (_cache[7] = _createElementVNode("span", null, "Attributes", -1)),
            _withDirectives(_createElementVNode("select", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.attributeId) = $event)),
              class: "block bg-bg-0 w-full rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-text-alt focus:ring-text-alt focus:ring-1 sm:text-sm text-text-alt body-2 !leading-4 font-bold"
            }, [
              _cache[6] || (_cache[6] = _createElementVNode("option", { value: "-1" }, "---", -1)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes, (attr, key) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: key,
                  value: attr.id
                }, _toDisplayString(attr.name), 9, _hoisted_7))
              }), 128))
            ], 512), [
              [_vModelSelect, _ctx.attributeId]
            ])
          ]),
          _cache[11] || (_cache[11] = _createElementVNode("span", null, "Date", -1)),
          _createElementVNode("label", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, [
              _createVNode(_component_CalendarIcon, { class: "h-5 w-5 fill-text-alt stroke-text-alt" })
            ]),
            _withDirectives(_createElementVNode("input", {
              class: "block bg-bg-0 w-full rounded-md py-2 pl-9 pr-3 shadow-sm focus:outline-none focus:border-text-alt focus:ring-text-alt focus:ring-1 sm:text-sm text-text-alt body-2 !leading-4 font-bold",
              placeholder: "Date",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.date) = $event)),
              type: "date",
              name: "Date"
            }, null, 512), [
              [_vModelText, _ctx.date]
            ])
          ]),
          (_ctx.file === null)
            ? (_openBlock(), _createBlock(_component_DropZone, {
                key: 0,
                "data-active": "false",
                class: "drop-area mt-10",
                onFilesDropped: _ctx.addFiles
              }, {
                default: _withCtx(({ dropZoneActive }) => [
                  _createElementVNode("label", _hoisted_10, [
                    dropZoneActive
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, _cache[8] || (_cache[8] = [
                          _createElementVNode("span", null, "Drop Them Here", -1),
                          _createElementVNode("span", { class: "smaller" }, "to add them", -1)
                        ])))
                      : (_openBlock(), _createElementBlock("span", _hoisted_12, _cache[9] || (_cache[9] = [
                          _createElementVNode("span", null, "Drag Your Files Here", -1),
                          _createElementVNode("span", { class: "smaller" }, [
                            _createTextVNode(" or "),
                            _createElementVNode("strong", null, [
                              _createElementVNode("em", null, "click here")
                            ]),
                            _createTextVNode(" to select files ")
                          ], -1)
                        ]))),
                    _createElementVNode("input", {
                      type: "file",
                      id: "file-input",
                      multiple: "",
                      onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onInputChange && _ctx.onInputChange(...args)))
                    }, null, 32)
                  ])
                ]),
                _: 1
              }, 8, ["onFilesDropped"]))
            : (_ctx.file)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createVNode(_component_FilePreview, {
                    file: _ctx.file,
                    tag: "div",
                    onRemove: _ctx.removeFile
                  }, null, 8, ["file", "onRemove"]),
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.file.file.name), 1)
                ]))
              : _createCommentVNode("", true),
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.upload && _ctx.upload(...args))),
            class: "mt-10 text-gray-800 btn-gradient-yellow no-underline flex items-center justify-end justify-between hover:bg-bg-2 rounded-md p-0.5 xxs:p-2 mx-0 font-normal w-full lg:w-auto"
          }, [
            _createVNode(_component_UploadIcon, { class: "w-4 h-4 fill-gray-800 mr-3" }),
            _cache[10] || (_cache[10] = _createTextVNode(" Upload "))
          ])
        ]))
  ]))
}