import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, toRef } from 'vue'
import { FilterFormatted } from '@/core/model/filter.model'
import { useKpiRequestor } from '@/components/analytics/kpi/useKpiRequestor'
import { KpiInfo } from '@/core/model/kpi.model'
import ChartCardLoading from '@/components/analytics/charts/card.loading.vue'
import PError from '@/components/analytics/services/previews/error-preview.vue'
import PGauge from '@/components/analytics/services/previews/kpi/gauge.vue'
import PStandard from '@/components/analytics/services/previews/kpi/standard.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'kpi-preview',
  props: {
    kpi: {},
    filtersApplied: {}
  },
  setup(__props: any) {

const props = __props

const { values, isLoading, isError, error } = useKpiRequestor(props.kpi, toRef(props.filtersApplied))

const isGauge = computed(() => props.kpi.type === 'SOLID_GAUGE' || props.kpi.type === 'GAUGE')


return (_ctx: any,_cache: any) => {
  return (_unref(isLoading))
    ? (_openBlock(), _createBlock(ChartCardLoading, { key: 0 }))
    : (_unref(isError))
      ? (_openBlock(), _createBlock(PError, {
          key: 1,
          error: _unref(error)
        }, null, 8, ["error"]))
      : (isGauge.value)
        ? (_openBlock(), _createBlock(PGauge, {
            key: 2,
            kpi: _ctx.kpi,
            values: _unref(values)
          }, null, 8, ["kpi", "values"]))
        : (_openBlock(), _createBlock(PStandard, {
            key: 3,
            kpi: _ctx.kpi,
            values: _unref(values)
          }, null, 8, ["kpi", "values"]))
}
}

})