import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_search_bar_popover = _resolveComponent("search-bar-popover")!
  const _component_map_layers_popover = _resolveComponent("map-layers-popover")!
  const _component_advanced_filter_popover = _resolveComponent("advanced-filter-popover")!
  const _component_zone_popover = _resolveComponent("zone-popover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_link, {
      "data-target-id": "searchBarPopover",
      to: { query : {...this.$route.query,  popover: 'searchBarPopover' }},
      title: "Search",
      class: "m-2 py-2 px-3 block rounded-md duration-700 dark:text-slate-300 :dark:hover:text-white dark:bg-slate-800 dark:hover:bg-slate-700 bg-white/50 hover:bg-white/100",
      style: {"box-shadow":"0 0 10px rgba(169,184,219,.4)"}
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("i", { class: "fa-regular fa-search" }, null, -1)
      ])),
      _: 1
    }, 8, ["to"]),
    _createVNode(_component_router_link, {
      "data-target-id": "mapLayersPopover",
      to: { query : {...this.$route.query,  popover: 'mapLayersPopover' }},
      title: "Map layers",
      class: "m-2 py-2 px-3 block rounded-md duration-700 dark:text-slate-300 :dark:hover:text-white dark:bg-slate-800 dark:hover:bg-slate-700 bg-white/50 hover:bg-white/100",
      style: {"box-shadow":"0 0 10px rgba(169,184,219,.4)"}
    }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createElementVNode("i", { class: "fa-regular fa-layer-group" }, null, -1)
      ])),
      _: 1
    }, 8, ["to"]),
    _createVNode(_component_router_link, {
      "data-target-id": "advancedFilterPopover",
      to: { query : {...this.$route.query,  popover: 'advancedFilterPopover' }},
      title: "Filter assets",
      class: "m-2 py-2 px-3 block rounded-md duration-700 dark:text-slate-300 :dark:hover:text-white dark:bg-slate-800 dark:hover:bg-slate-700 bg-white/50 hover:bg-white/100",
      style: {"box-shadow":"0 0 10px rgba(169,184,219,.4)"}
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("i", { class: "fa-regular fa-filter" }, null, -1)
      ])),
      _: 1
    }, 8, ["to"]),
    _createVNode(_component_router_link, {
      "data-target-id": "zonePopover",
      to: { query : {...this.$route.query,  popover: 'zonePopover' }},
      title: "Filter zones",
      class: "m-2 py-2 px-3 block rounded-md duration-700 dark:text-slate-300 :dark:hover:text-white dark:bg-slate-800 dark:hover:bg-slate-700 bg-white/50 hover:bg-white/100",
      style: {"box-shadow":"0 0 10px rgba(169,184,219,.4)"}
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("i", { class: "fa-solid fa-draw-polygon" }, null, -1)
      ])),
      _: 1
    }, 8, ["to"]),
    (_openBlock(), _createBlock(_Teleport, { to: "#end-of-body" }, [
      ( _ctx.$route.query.popover === 'searchBarPopover' )
        ? (_openBlock(), _createBlock(_component_search_bar_popover, { key: 0 }))
        : _createCommentVNode("", true),
      ( _ctx.$route.query.popover === 'mapLayersPopover' )
        ? (_openBlock(), _createBlock(_component_map_layers_popover, { key: 1 }))
        : _createCommentVNode("", true),
      ( _ctx.$route.query.popover === 'advancedFilterPopover' )
        ? (_openBlock(), _createBlock(_component_advanced_filter_popover, { key: 2 }))
        : _createCommentVNode("", true),
      ( _ctx.$route.query.popover === 'zonePopover' )
        ? (_openBlock(), _createBlock(_component_zone_popover, { key: 3 }))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}