import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 2,
  class: "text-gray-200"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.tabs.length && !_ctx.error)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.tabGroupClass)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: _normalizeClass([
        { 'border-b-2 !border-filter text-filter': tab.displayed },
        _ctx.tabClass,
      ])
          }, [
            _createElementVNode("span", {
              class: "cursor-pointer z-10",
              onClick: ($event: any) => (_ctx.selectTab(i))
            }, _toDisplayString(tab.name), 9, _hoisted_1)
          ], 2))
        }), 128)),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.tabBottomClass)
        }, null, 2)
      ], 2))
    : (!_ctx.tabs.length && !_ctx.error)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.tabGroupClass)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.tabClass)
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("div", { class: "z-10 skeleton" }, null, -1)
          ]), 2),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.tabBottomClass)
          }, null, 2)
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.error), 1))
}