import { ChartType } from '@/core/model/chart.model'

import BarAndLineChart from '@/components/analytics/charts/visuals/bar-and-line.chart.vue'
import BarChart from '@/components/analytics/charts/visuals/bar.chart.vue'
import Gauge from '@/components/analytics/charts/visuals/gauge.chart.vue'
import HBar from '@/components/analytics/charts/visuals/horizontal-bar.chart.vue'
import Line from '@/components/analytics/charts/visuals/line.chart.vue'
import Pie from '@/components/analytics/charts/visuals/pie.chart.vue'
import Undefined from '@/components/analytics/charts/visuals/undefined.chart.vue'
import UnstackBar from '@/components/analytics/charts/visuals/unstack-bar.chart.vue'

// import Highcharts from 'highcharts'
// import highchartsMore from 'highcharts/highcharts-more'
// import solidGauge from 'highcharts/modules/solid-gauge'

// highchartsMore(Highcharts)
// solidGauge(Highcharts)

export function getVisualComponent (type: ChartType) {
  switch (type) {
    case 'BAR': return BarChart
    case 'BARXLINE': return BarAndLineChart
    case 'GAUGE': return Gauge
    case 'H-BAR': return HBar
    case 'LINE': return Line
    case 'PIE': return Pie
    case 'UNSTACK-BAR': return UnstackBar

    default: return Undefined
  }
}
