import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center px-6 pb-4" }
const _hoisted_2 = { style: {"flex":"1"} }
const _hoisted_3 = { class: "mb-1.5" }
const _hoisted_4 = { class: "body-1 body-alt" }
const _hoisted_5 = { class: "mr-2" }
const _hoisted_6 = {
  key: 0,
  class: "cursor-pointer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FilesIcon = _resolveComponent("FilesIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UploadIcon = _resolveComponent("UploadIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FilesIcon, { class: "w-8 h-8 fill-gray-200 mr-3" }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.subtitle), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_router_link, {
        to: { query : {...this.$route.query,  popover: 'subscription_popover', id:_ctx.id }},
        id: `target_subscription_popover_${_ctx.id}`,
        class: "border border-white py-1 px-3 rounded inline-block flex",
        style: {"width":"220px","align-items":"center"}
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createElementVNode("span", { style: {"flex":"1"} }, "Alert email", -1),
          _createElementVNode("i", { class: "fa fa-chevron-down" }, null, -1)
        ])),
        _: 1
      }, 8, ["to", "id"])
    ]),
    (_ctx.isInternalUser)
      ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
          _createVNode(_component_UploadIcon, {
            class: "w-8 h-8 fill-gray-200 mr-3",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.requestDocumentUpload()))
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}