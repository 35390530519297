import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-100 d-flex justify-center align-center"
}
const _hoisted_2 = {
  key: 0,
  class: "d-flex justify-center align-center",
  style: {"height":"200px"}
}
const _hoisted_3 = { class: "d-flex flex-column align-center" }
const _hoisted_4 = { class: "d-flex justify-space-around w-100" }
const _hoisted_5 = { class: "text-left font-weight-bold" }
const _hoisted_6 = {
  class: "d-inline-block",
  style: {"width":"100px"}
}
const _hoisted_7 = {
  class: "d-inline-block",
  style: {"width":"100px"}
}
const _hoisted_8 = { class: "text-left font-weight-bold" }
const _hoisted_9 = {
  class: "d-inline-block",
  style: {"width":"100px"}
}
const _hoisted_10 = {
  class: "d-inline-block",
  style: {"width":"100px"}
}
const _hoisted_11 = {
  class: "text-left font-weight-bold text-uppercase",
  style: {"width":"250px"}
}
const _hoisted_12 = { style: {"width":"70px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_period_expand = _resolveComponent("period-expand")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_ctx.loading || _ctx.loadingFilter)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_v_progress_circular, { indeterminate: "" })
      ]))
    : (_openBlock(), _createBlock(_component_v_row, {
        key: 1,
        class: "h-100"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "8" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_card, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_card_text, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_toolbar, {
                        color: "transparent",
                        class: "align-center pe-4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_app_bar_nav_icon, {
                            icon: "mdi-arrow-left",
                            to: {name : 'operations', params : { rangeTimeId: this.$route.params.rangeTimeId }}
                          }, null, 8, ["to"]),
                          _createVNode(_component_v_toolbar_title, { style: {"margin-inline-start":"0"} }, {
                            default: _withCtx(() => _cache[2] || (_cache[2] = [
                              _createElementVNode("h2", null, "Periods scheduling", -1)
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_v_select, {
                            "model-value": parseInt(_ctx.$route.params.assetId.toString()),
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = x=> this.$router.push({params : { assetId : x}}) ),
                            "hide-details": true,
                            class: "me-2",
                            items: _ctx.filterByContract(_ctx.planningFilter.assets, parseInt(_ctx.$route.params.contractId))
                          }, {
                            selection: _withCtx(({ item }) => [
                              _createVNode(_component_v_badge, {
                                color: _ctx.getAssetFilter(_ctx.planningFilter.assets,item.raw.id).status_color,
                                dot: "",
                                "offset-y": "-5",
                                "offset-x": "5"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_img, {
                                    width: 40,
                                    rounded: "",
                                    gradient: "180deg, rgba(0,0,0,0) 80%, rgba(0,0,0,1) 100%",
                                    class: "me-2",
                                    src: _ctx.getAssetFilter(_ctx.planningFilter.assets,item.raw.id).image_url
                                  }, null, 8, ["src"])
                                ]),
                                _: 2
                              }, 1032, ["color"]),
                              _createTextVNode(" " + _toDisplayString(item.raw.name), 1)
                            ]),
                            _: 1
                          }, 8, ["model-value", "items"]),
                          _createVNode(_component_v_btn, {
                            variant: "flat",
                            density: "default",
                            border: "",
                            color: "primary",
                            class: "text-capitalize",
                            to: { name : 'periodDetail', params : { contractId : _ctx.$route.params.contractId, assetId : _ctx.$route.params.assetId, periodId : _ctx.$route.params.periodId, entryId : 'new' }}
                          }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode("New period")
                            ])),
                            _: 1
                          }, 8, ["to"])
                        ]),
                        _: 1
                      }),
                      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "py-4 flex-grow-1 d-flex justify-space-around" }, null, -1)),
                      (!_ctx.loading && !_ctx.loadingFilter)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (_ctx.entryByStatus(_ctx.entriesForMonth.entries).length===0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                  _createElementVNode("div", _hoisted_3, [
                                    _cache[5] || (_cache[5] = _createElementVNode("h3", { class: "mb-2 italic" }, "List empty...", -1)),
                                    _createVNode(_component_v_icon, {
                                      icon: "mdi-list-box-outline",
                                      size: "x-large",
                                      class: "mb-4"
                                    }),
                                    _createVNode(_component_v_btn, {
                                      variant: "flat",
                                      density: "default",
                                      border: "",
                                      color: "primary",
                                      to: { name : 'periodDetail', params : { contractId : _ctx.$route.params.contractId, assetId : _ctx.$route.params.assetId, periodId : _ctx.$route.params.periodId, entryId : 'new' }}
                                    }, {
                                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                                        _createTextVNode("New period")
                                      ])),
                                      _: 1
                                    }, 8, ["to"])
                                  ])
                                ]))
                              : (_openBlock(), _createBlock(_component_v_expansion_panels, {
                                  key: 1,
                                  modelValue: _ctx.expandEntryId,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.expandEntryId) = $event)),
                                  elevation: "0",
                                  "bg-color": "surface-light",
                                  class: "border",
                                  variant: "accordion"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_expansion_panel, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_expansion_panel_title, { class: "px-0 py-6" }, {
                                          actions: _withCtx(() => _cache[6] || (_cache[6] = [
                                            _createElementVNode("div", { style: {"width":"70px"} }, null, -1)
                                          ])),
                                          default: _withCtx(() => [
                                            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "d-flex justify-space-around w-100 font-weight-bold" }, [
                                              _createElementVNode("div", {
                                                class: "text-left",
                                                style: {"width":"200px"}
                                              }, "Start time"),
                                              _createElementVNode("div", {
                                                class: "text-left",
                                                style: {"width":"200px"}
                                              }, "End time"),
                                              _createElementVNode("div", {
                                                class: "text-left",
                                                style: {"width":"250px"}
                                              }, "Status")
                                            ], -1))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.entryByStatus(_ctx.entriesForMonth.entries), (entry, index) => {
                                      return (_openBlock(), _createBlock(_component_v_expansion_panel, {
                                        key: index,
                                        value: entry.id
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_v_expansion_panel_title, { class: "px-0" }, {
                                            actions: _withCtx(({ expanded }) => [
                                              _createElementVNode("div", _hoisted_12, [
                                                _createVNode(_component_v_btn, {
                                                  icon: expanded ? 'mdi-chevron-down ' : 'mdi-chevron-up',
                                                  variant: "text",
                                                  color: "primary",
                                                  density: "compact",
                                                  onClick: _withModifiers(($event: any) => (_ctx.toggleExpand(entry.id)), ["prevent"])
                                                }, null, 8, ["icon", "onClick"]),
                                                _createVNode(_component_v_btn, {
                                                  id: `menu${index}`,
                                                  icon: "mdi-dots-vertical",
                                                  variant: "text",
                                                  color: "primary",
                                                  disabled: !entry.editable && !entry.deletable,
                                                  density: "compact"
                                                }, null, 8, ["id", "disabled"])
                                              ]),
                                              _createVNode(_component_v_menu, {
                                                activator: `#menu${index}`,
                                                "open-on-click": true,
                                                transition: "slide-x-transition",
                                                location: "bottom right",
                                                "open-on-hover": true
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_list, null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_v_list_item, {
                                                        "prepend-icon": "mdi-pencil",
                                                        variant: "tonal",
                                                        slim: "",
                                                        link: "",
                                                        "base-color": "primary",
                                                        to: { name : 'periodDetail', params : { contractId : _ctx.$route.params.contractId, assetId : _ctx.$route.params.assetId, periodId : _ctx.$route.params.periodId,  entryId : entry.id }}
                                                      }, {
                                                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                                                          _createTextVNode("Edit")
                                                        ])),
                                                        _: 2
                                                      }, 1032, ["to"]),
                                                      _createVNode(_component_v_list_item, {
                                                        "prepend-icon": "mdi-trash-can-outline",
                                                        variant: "tonal",
                                                        slim: "",
                                                        link: "",
                                                        "base-color": "error",
                                                        onClick: ($event: any) => (_ctx.onDelete(_ctx.$route.params.contractId, _ctx.$route.params.assetId, entry.id))
                                                      }, {
                                                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                          _createTextVNode("Delete")
                                                        ])),
                                                        _: 2
                                                      }, 1032, ["onClick"])
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ]),
                                                _: 2
                                              }, 1032, ["activator"])
                                            ]),
                                            default: _withCtx(() => [
                                              _createElementVNode("div", _hoisted_4, [
                                                _createElementVNode("div", _hoisted_5, [
                                                  _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.timeLineHelper.formatDate('%m/%d/%Y',entry.start_date)), 1),
                                                  _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.timeLineHelper.formatDate('%H:%M',entry.start_date)), 1)
                                                ]),
                                                _createElementVNode("div", _hoisted_8, [
                                                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.timeLineHelper.formatDate('%m/%d/%Y',entry.end_date)), 1),
                                                  _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.timeLineHelper.formatDate('%H:%M',entry.end_date)), 1)
                                                ]),
                                                _createElementVNode("div", _hoisted_11, _toDisplayString(entry.status), 1)
                                              ])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_v_expansion_panel_text, null, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_period_expand, {
                                                "entry-id": entry.id
                                              }, null, 8, ["entry-id"])
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1032, ["value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"]))
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { cols: "4" }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createElementVNode("h3", null, "placeholder calendar", -1)
            ])),
            _: 1
          })
        ]),
        _: 1
      }))
}