import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, Teleport as _Teleport } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 1,
  class: "py-3 lg:py-6 h-100 relative"
}
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "table w-full" }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "flex flex-col report-upload-form bg-bg-0" }
const _hoisted_8 = { class: "min-w-[300px] lg:max-w-[80%]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportAttributeTabLoading = _resolveComponent("ReportAttributeTabLoading")!
  const _component_ReportInstanceLoading = _resolveComponent("ReportInstanceLoading")!
  const _component_ReportAttributeTab = _resolveComponent("ReportAttributeTab")!
  const _component_ReportInstanceRow = _resolveComponent("ReportInstanceRow")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_ServiceReportForm = _resolveComponent("ServiceReportForm")!
  const _component_GenericCardShell = _resolveComponent("GenericCardShell")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (this.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ReportAttributeTabLoading),
          _createVNode(_component_ReportInstanceLoading)
        ]))
      : (_ctx.report && _ctx.report.attributes.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ReportAttributeTab, {
              attributes: _ctx.report.attributes,
              onUpdateSelectedAttribute: _ctx.updateSelectedAttribute
            }, null, 8, ["attributes", "onUpdateSelectedAttribute"]),
            (_ctx.loadingInstance)
              ? (_openBlock(), _createBlock(_component_ReportInstanceLoading, { key: 0 }))
              : (_ctx.instances.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("table", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.instances, (item, i) => {
                          return (_openBlock(), _createBlock(_component_ReportInstanceRow, {
                            key: i,
                            item: item,
                            reportId: _ctx.report.id,
                            onDownload: ($event: any) => (_ctx.download(item)),
                            onDelete: _ctx.onDelete
                          }, null, 8, ["item", "reportId", "onDownload", "onDelete"]))
                        }), 128))
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
            (!_ctx.loadingInstance && _ctx.totalPages > 1)
              ? (_openBlock(), _createBlock(_component_Pagination, {
                  key: 2,
                  currentPage: _ctx.currentPage,
                  totalPages: _ctx.totalPages,
                  numberOfButtonAfterCurrent: 4,
                  onSelectPage: _cache[0] || (_cache[0] = selectedPage => _ctx.fetchReports(selectedPage - 1))
                }, null, 8, ["currentPage", "totalPages"]))
              : _createCommentVNode("", true)
          ]))
        : (!_ctx.report.attributes.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, "No reports found for this category"))
          : _createCommentVNode("", true),
    (_ctx.showForm)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 3,
          to: "body"
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_GenericCardShell, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ServiceReportForm, {
                    id: _ctx.report.id,
                    title: _ctx.report.name,
                    onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeForm()))
                  }, null, 8, ["id", "title"])
                ]),
                _: 1
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}