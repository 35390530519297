import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "viewport bg-bg-2 rounded-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_service_preview = _resolveComponent("service-preview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sources, (item, index) => {
      return (_openBlock(), _createBlock(_Transition, {
        key: index,
        name: `slide-${_ctx.direction}`
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_service_preview, {
            service: item,
            filters: [
              { id: _ctx.periodFilter, members: 'L1W', isLoading: false },
              { id: _ctx.assetFilter, members: [_ctx.assetId] , isLoading: false },
            ],
            style: {"justify-self":"center"}
          }, null, 8, ["service", "filters"]), [
            [_vShow, index === _ctx.current]
          ])
        ]),
        _: 2
      }, 1032, ["name"]))
    }), 128))
  ]))
}