import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "relative",
  style: {"margin":"-8px"}
}
const _hoisted_2 = { class: "absolute w-full h-full z-[10] bottom-0 hidden md:block pointer-events-none" }

import { useTemplateRef, onMounted, ref, onUnmounted } from 'vue'
import RightArrowIcon from '@/components/icons/RightArrowIcon.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'horizontal-arrow-scoller',
  setup(__props) {

const scroller = useTemplateRef('scroller')

const showLeftArrow = ref<boolean>(false)
const showRightArrow = ref<boolean>(false)

const resizeObserver = new ResizeObserver(showOrHideArrows)

onMounted(() => {
  if (scroller.value) {
    resizeObserver.observe(scroller.value)
  }
})

onUnmounted(() => resizeObserver.disconnect())

function showOrHideArrows () {
  if (!scroller.value) {
    showLeftArrow.value = false
    showRightArrow.value = false
    return
  }

  showLeftArrow.value = scroller.value.scrollLeft > 0
  showRightArrow.value = scroller.value.scrollLeft < (scroller.value.scrollWidth - scroller.value.offsetWidth)
}

function scrollCategory (direction: 'right' | 'left') {
  if (scroller.value === null) return

  if (direction === 'left') {
    scroller.value.scroll({ left: scroller.value.scrollLeft - 400, behavior: 'smooth' })
  } else {
    scroller.value.scroll({ left: scroller.value.scrollLeft + 400, behavior: 'smooth' })
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref_key: "scroller",
      ref: scroller,
      onScroll: showOrHideArrows,
      style: {"padding":"8px"},
      class: "flex gap-4 w-full custom-scrollbar overflow-x-auto overflow-y-hidden no-scroll"
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 544),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([showLeftArrow.value ? 'flex': 'hidden', "h-full w-[120px] absolute arrow-shadow-1 flex flex-col justify-center left-0 lg:-left-6 items-start"])
      }, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (scrollCategory('left'))),
          class: "rounded-full p-2.5 bg-bg-2 w-fit pointer-events-auto rotate-180"
        }, [
          _createVNode(RightArrowIcon, { class: "w-5 h-5 stroke-text-prim" })
        ])
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass([showRightArrow.value ? 'flex': 'hidden', "h-full w-[120px] absolute arrow-shadow-2 flex flex-col justify-center right-0 lg:-right-6 items-end"])
      }, [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (scrollCategory('right'))),
          class: "rounded-full p-2.5 bg-bg-2 w-fit pointer-events-auto"
        }, [
          _createVNode(RightArrowIcon, { class: "w-5 h-5 stroke-text-prim" })
        ])
      ], 2)
    ])
  ]))
}
}

})