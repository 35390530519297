import {
  ReportAttribute,
  ReportDetail,
  ReportsListModel,
  UploadAssetReportModel,
  UploadServiceReportModel
} from '@/core/model/report.model'
import { ApiResult, http } from '@/core/services/request.service'

class Manager {
  private _attributes:Array<any> = []

  detail (id: number): Promise<ApiResult<ReportDetail>> {
    return http.request<ReportDetail>('get', `Services/Reports/${id}`)
      .then((rep: ApiResult<ReportDetail>) => {
        return rep
      })
      .catch(err => {
        return err
      })
  }

  async attributes () : Promise<Array<any>> {
    if (this._attributes.length === 0) {
      const response = await http.request<Array<any>>('get', 'Services/Reports/Attributes/All')
      if (response.data && !response.isError) {
        this._attributes = response.data
      }
    }
    return this._attributes
  }

  detailAsset (id: number, assetId: number): Promise<ApiResult<ReportDetail>> {
    return http.request<ReportDetail>('get', `Services/Reports/${id}/Assets/${assetId}`)
      .then((rep: ApiResult<ReportDetail>) => {
        return rep
      })
      .catch(err => {
        return err
      })
  }

  async DownloadInstance (displayFilename:string, filename:string, tinyGUID: string) : Promise<void> {
    const query = `Services/Reports/Download?tinyGuid=${tinyGUID}`

    const response = await http.download(query)

    if (response.isError) return

    const aElement = document.createElement('a')

    aElement.setAttribute('download', filename === 'from email' ? response.Message : filename)

    const href = URL.createObjectURL(response.data)

    aElement.href = encodeURI(`${href}`)
    aElement.click()
    URL.revokeObjectURL(response.data)
  }

  byVersion (id: number, instanceId: number, version: number) : Promise<ApiResult<ArrayBuffer>> {
    return http.request<ArrayBuffer>('get', `Reports/${id}/Instance/${instanceId}/Version/${version}`)
      .then((rep: ApiResult<ArrayBuffer>) => {
        return rep
      })
      .catch(err => {
        return err
      })
  }

  byAttribute (id: number, attributeId: number, pageIndex = 0, pageSize = 2) : Promise<ApiResult<ReportAttribute>> {
    return http.request<ReportAttribute>('get', `Services/Reports/${id}/Attribute/${attributeId}?pageIndex=${pageIndex}&pageSize=${pageSize}`)
      .then((rep: ApiResult<ReportAttribute>) => {
        return rep
      })
      .catch(err => {
        return err
      })
  }

  byAttributeAsset (id: number, assetId: number, attributeId: number, pageIndex = 0, pageSize = 10) : Promise<ApiResult<ReportAttribute>> {
    return http.request<ReportAttribute>('get', `Services/Reports/${id}/Assets/${assetId}/Attribute/${attributeId}?pageIndex=${pageIndex}&pageSize=${pageSize}`)
      .then((rep: ApiResult<ReportAttribute>) => {
        return rep
      })
      .catch(err => {
        return err
      })
  }

  list (assetRelated = false): Promise<ApiResult<ReportsListModel>> {
    return http.request<ReportsListModel>('get', `Services/Reports?assetRelatedOnly=${assetRelated}`)
      .then((rep: ApiResult<ReportsListModel>) => {
        return rep
      })
      .catch(err => {
        return err
      })
  }

  async upload (model: UploadServiceReportModel): Promise<any> {
    // set up the request data
    const formData = new FormData()
    formData.append('File', model.File.file)
    formData.append('Id_Attribute', model.attributeId.toString())
    formData.append('Title', model.Title.toString())
    formData.append('ClientDepartmentCode', model.ClientDepartmentCode.toString())
    formData.append('Date', model.Date.toString())

    await http.postFormData<any>(`Services/Reports/${model.reportId}`, formData)
    // let response = await fetch(url, { method: 'POST', body: apiModel })
  }

  async uploadForAsset (model: UploadAssetReportModel): Promise<any> {
    // set up the request data
    const formData = new FormData()
    formData.append('File', model.File.file)
    formData.append('Id_Attribute', model.attributeId.toString())
    formData.append('Title', model.Title.toString())
    formData.append('ClientDepartmentCode', model.ClientDepartmentCode.toString())
    formData.append('Date', model.Date.toString())

    await http.postFormData<any>(`Services/Reports/${model.reportId}/Assets/${model.assetId}`, formData)
    // let response = await fetch(url, { method: 'POST', body: apiModel })
  }
}

export default new Manager()
