import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["stroke", "fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    height: "682pt",
    viewBox: "-21 -62 682.66669 682",
    width: "682pt",
    xmlns: "http://www.w3.org/2000/svg",
    stroke: _ctx.stroke,
    fill: _ctx.fill
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m640 506.417969h-440v-50h440zm-490-25c0-41.359375-33.640625-75-75-75s-75 33.640625-75 75c0 41.355469 33.640625 75 75 75s75-33.644531 75-75zm-50 0c0 13.78125-11.214844 25-25 25s-25-11.21875-25-25c0-13.785157 11.214844-25 25-25s25 11.214843 25 25zm540-233.75h-440v50h440zm-490 25c0-41.359375-33.640625-75-75-75s-75 33.640625-75 75c0 41.355469 33.640625 75 75 75s75-33.644531 75-75zm-50 0c0 13.78125-11.214844 25-25 25s-25-11.21875-25-25c0-13.785157 11.214844-25 25-25s25 11.214843 25 25zm540-225h-440v50h440zm-490 25c0-41.359375-33.640625-75-75-75s-75 33.640625-75 75c0 41.355469 33.640625 75 75 75s75-33.644531 75-75zm-50 0c0 13.78125-11.214844 25-25 25s-25-11.21875-25-25c0-13.785157 11.214844-25 25-25s25 11.214843 25 25zm0 0" }, null, -1)
  ]), 8, _hoisted_1))
}