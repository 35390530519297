import Tools from '@/core/services/tools'
import Layout1 from '@/views/layouts/layout1.vue'
import AnalyticsDetail from '@/views/pages/Analytics.detail.vue'
import AnalyticsList from '@/views/pages/Analytics.list.vue'
import Dashboard from '@/views/pages/Dashboard.vue'
import Dashboards from '@/views/pages/Dashboards.list.vue'
import NotFound from '@/views/pages/errors/NotFound.vue'
import Fleet from '@/views/pages/Fleet.vue'
import FleetAsset from '@/views/pages/FleetAsset.vue'
import Home from '@/views/pages/Home.vue'
import Profile from '@/views/pages/Profile.vue'
import ReportsList from '@/views/pages/Reports.list.vue'
import TabMap from '@/map/views/tabMap.vue'
import ReportManager from '@/core/managers/report.manager'
import { _RouteLocationBase, createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import planningPage from '@/views/feat/timelinePage.vue'
import periodPage from '@/views/feat/periodPage.vue'
import periodDetail from '@/views/feat/periodDetail.vue'

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: 'home' },
  {
    path: '/layout-1',
    component: Layout1,
    children: [
      { path: '/home', name: 'home', component: Home },
      { path: '/analytics', name: 'analytics', component: AnalyticsList },
      { path: '/map', name: 'map', component: TabMap },
      { path: '/analytics/:analyticsId/:filter?/:member?', name: 'analyticsDetail', component: AnalyticsDetail, props: route => ({ analyticsId: route.params.analyticsId, filter: route.query.filter as string }), meta: { detail: true } },
      { path: '/profile', name: 'profile', component: Profile },
      { path: '/operations/contract/:contractId/rangeTime/:rangeTimeId', name: 'operations', component: planningPage, meta: { credential: 'isInternal' } },
      { path: '/period/contract/:contractId/asset/:assetId/rangeTime/:rangeTimeId', name: 'period', component: periodPage, meta: { credential: 'isInternal' } },
      { path: '/period/contract/:contractId/asset/:assetId/rangeTime/:rangeTimeId/entryId/:entryId', name: 'periodDetail', component: periodDetail, meta: { credential: 'isInternal' } },
      { path: '/dashboard/:dashboardId', name: 'dashboard', component: Dashboard, props: true },
      { path: '/dashboards', name: 'allDashboards', component: Dashboards },
      { path: '/reports', name: 'allReports', component: ReportsList },
      { path: '/fleet/:categoryId?', name: 'fleet', component: Fleet },
      { path: '/fleet/category/:categoryId/asset/:assetId', name: 'assetDetail', component: FleetAsset },
      { path: '/help-center', name: 'helpCenter', component: Home }
    ]
  },
  { path: '/server-error', name: 'server error', component: NotFound, props: { type: 'serverError' } },
  { path: '/unauthorized', name: 'unauthorized', component: NotFound, props: { type: 'unauthorized' } },
  { path: '/:pathMatch(.*)*', name: 'not found', component: NotFound }
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export const PAGE_TITLE: Record<string, string> = {
  home: 'Home',
  analytics: 'Analytics',
  analyticsDetail: 'Analytics Detail',
  dashboard: 'Dashboard',
  reports: 'Reports',
  allReports: 'All Reports',
  allDashboards: 'All Dashboards',
  fleet: 'Fleet',
  helpCenter: 'Help Center',
  assetDetail: 'Asset Detail',
  profile: 'Profile',
  unauthorized: 'Unauthorized',
  map: 'Map',
  operations: 'operations',
  period: 'period',
  periodDetail: 'period form'
}

export default router
router.beforeEach(async (to: _RouteLocationBase) => {
  if (to.name === 'allReports' && to.query && to.query.dl) {
    await ReportManager.DownloadInstance('', 'from email', to.query.dl as string)
  }
})
router.afterEach((toRoute: _RouteLocationBase) => {
  Tools.updatePageName(PAGE_TITLE[toRoute.name as string])
})
