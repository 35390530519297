import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "font-display text-white text-2xl my-4 font-bold" }
const _hoisted_4 = { class: "flex flex-wrap gap-4 justify-center lg:justify-start" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "flex flex-col gap-4" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "flex flex-wrap gap-4 justify-center lg:justify-start" }
const _hoisted_9 = {
  key: 0,
  class: "body body-1 font-bold mt-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardCard = _resolveComponent("DashboardCard")!
  const _component_DashboardsListMenuShell = _resolveComponent("DashboardsListMenuShell")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DashboardsListMenuShell, {
      services: _ctx.services,
      error: _ctx.error,
      loading: _ctx.loading,
      isDashboard: true,
      onSearchDashboard: _ctx.filterServices
    }, {
      default: _withCtx(() => [
        (!_ctx.loading && _ctx.services.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (service, i) => {
                return (_openBlock(), _createElementBlock("div", { key: i }, [
                  _createVNode(_TransitionGroup, {
                    name: "fade",
                    tag: "div"
                  }, {
                    default: _withCtx(() => [
                      (service.active)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createElementVNode("h2", _hoisted_3, _toDisplayString(service.name), 1),
                            _createElementVNode("div", _hoisted_4, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(service.services, (serv, j) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: j,
                                  class: "w-full"
                                }, [
                                  (serv.active)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                        _createElementVNode("div", _hoisted_6, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(serv.dashboards, (group, index) => {
                                            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                                              (group.active)
                                                ? (_openBlock(), _createBlock(_component_DashboardCard, {
                                                    key: index,
                                                    title: group.title,
                                                    service: serv.name,
                                                    previewImageUrl: group.previewImageUrl,
                                                    description: group.description,
                                                    id: group.id
                                                  }, null, 8, ["title", "service", "previewImageUrl", "description", "id"]))
                                                : _createCommentVNode("", true)
                                            ], 64))
                                          }), 128))
                                        ])
                                      ]))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128))
                            ]),
                            _cache[0] || (_cache[0] = _createElementVNode("hr", { class: "my-6 border-t-gray-800" }, null, -1))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
                return _createElementVNode("div", { key: i }, [
                  _createElementVNode("div", null, [
                    _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "font-display text-white text-2xl my-4 font-bold skeleton" }, null, -1)),
                    _createElementVNode("div", _hoisted_8, [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (index) => {
                        return _createVNode(_component_DashboardCard, {
                          key: index,
                          loading: _ctx.loading
                        }, null, 8, ["loading"])
                      }), 64))
                    ])
                  ])
                ])
              }), 64))
            ]))
      ]),
      _: 1
    }, 8, ["services", "error", "loading", "onSearchDashboard"]),
    (!_ctx.services.length && !_ctx.error && !_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t("allReports.notFound")), 1))
      : _createCommentVNode("", true)
  ], 64))
}