import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "py-3 lg:py-6 h-100 relative" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "table w-full" }
const _hoisted_4 = { class: "py-3 px-2.5 w-3" }
const _hoisted_5 = { class: "py-3 pr-2.5 flex justify-end" }
const _hoisted_6 = { class: "h-10 w-10 bg-bg-2 rounded-full body-1 text-text-alt font-bold flex items-center justify-center cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DownloadIcon = _resolveComponent("DownloadIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", _hoisted_3, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (i) => {
          return _createElementVNode("tr", {
            key: i,
            class: "py-3 px-2.5"
          }, [
            _createElementVNode("td", _hoisted_4, [
              (_openBlock(), _createBlock(_resolveDynamicComponent('blank'), { class: "w-6 h-auto stroke-semantic-activate fill-semantic-activate" }))
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("td", { class: "py-3 pl-2.5" }, [
              _createElementVNode("div", { class: "skeleton" })
            ], -1)),
            _createElementVNode("td", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, [
                _createVNode(_component_DownloadIcon, { class: "stroke-white" })
              ])
            ])
          ])
        }), 64))
      ])
    ])
  ]))
}