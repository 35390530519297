import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import * as CategoryManager from '@/core/managers/category.manager'
import { CategoryModel } from '@/core/model/category.model'
import { State } from '@/store'

export function useCategories () {
  const toast = useToast()
  const store = useStore<State>()
  const categories = ref<CategoryModel[]>(store.state.list)
  const isLoading = ref<boolean>(false)
  const error = ref<string>()

  if (categories.value.length === 0) {
    isLoading.value = true
    onMounted(async () => {
      store.dispatch('setFiltersList', [])
      store.dispatch('setServiceList', [])
      const response = await CategoryManager.Manager.list()
      if (response.isError || response.data === undefined) {
        error.value = 'No services available at the moment. Please try again later'
        toast.error(error)
      } else {
        categories.value = response.data.categories
        store.dispatch('setServiceList', response.data.categories)
      }
      isLoading.value = false
    })
  }

  return { categories, isLoading, error }
}
