import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full flex justify-between" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "w-full mt-6" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 3,
  class: "flex flex-col items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FleetAssetTimelineModal = _resolveComponent("FleetAssetTimelineModal")!
  const _component_TimelineZoom = _resolveComponent("TimelineZoom")!
  const _component_FleetAssetTimelineDetail = _resolveComponent("FleetAssetTimelineDetail")!
  const _component_GenericCardShell = _resolveComponent("GenericCardShell")!

  return (_openBlock(), _createBlock(_component_GenericCardShell, { class: "rounded-lg pt-2 pb-6 px-2 sm:px-6 relative" }, {
    default: _withCtx(() => [
      (_ctx.openModal)
        ? (_openBlock(), _createBlock(_component_FleetAssetTimelineModal, {
            key: 0,
            onCloseModal: _ctx.closeModal,
            operation: _ctx.modalContent
          }, null, 8, ["onCloseModal", "operation"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.selectedTimeline !== 'journey')
            ? (_openBlock(), _createBlock(_component_TimelineZoom, {
                key: 0,
                onZoom: _ctx.handleZoomLevel,
                zoomLevel: _ctx.zoomLevel,
                class: "mr-2"
              }, null, 8, ["onZoom", "zoomLevel"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.timeline.length && _ctx.selectedTimeline === 'journey' && !_ctx.loading)
          ? (_openBlock(), _createBlock(_component_FleetAssetTimelineDetail, {
              key: 0,
              timeline: _ctx.timeline,
              openModal: _ctx.openModal,
              modalContent: _ctx.modalContent,
              onShowActivity: _ctx.showDetail
            }, null, 8, ["timeline", "openModal", "modalContent", "onShowActivity"]))
          : (!_ctx.timeline.length && !_ctx.error && !_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t('fleet.noTimeSheet')), 1))
            : (!_ctx.timeline.length && _ctx.error && !_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.error), 1))
              : (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "skeleton-main mb-3" }, null, -1)),
                    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "skeleton-title mb-6" }, null, -1)),
                    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(8, (i) => {
                      return _createElementVNode("div", {
                        class: "skeleton-body mb-1",
                        key: i
                      })
                    }), 64))
                  ]))
                : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}