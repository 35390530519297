import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "select custom-select w-70 skeleton"
}
const _hoisted_2 = {
  key: 1,
  class: "select custom-select w-280 vue-treeselect__control"
}

import { computed, onMounted, ref, toRef, watch } from 'vue'
import Treeselect from 'vue3-treeselect'
import 'vue3-treeselect/dist/vue3-treeselect.css'
import { useFilterRequestor } from '@/components/analytics/filters/useFilterRequestor'
import { FilterFormatted, FilterMember } from '@/core/model/filter.model'
import { FilterInServiceModel } from '@/core/model/service.model'

type filterOptions = {
  id: number|string
  label: string
  children?: Array<filterOptions>
}


export default /*@__PURE__*/_defineComponent({
  __name: 'filter-select',
  props: /*@__PURE__*/_mergeModels({
    filter: {},
    filtersApplied: {}
  }, {
    "isLoading": { type: Boolean },
    "isLoadingModifiers": {},
    "filterValue": {},
    "filterValueModifiers": {},
  }),
  emits: ["update:isLoading", "update:filterValue"],
  setup(__props: any) {

const props = __props

const { values, error, isLoading, isError } = useFilterRequestor(props.filter, toRef(props.filtersApplied))

const isLoadingModel = _useModel<boolean>(__props, 'isLoading')
const multiSelected = _useModel<string|string[]|number|number[]|null>(__props, 'filterValue')

const options = computed<filterOptions[]>(() => values.value?.members?.map(filterMemberMap) ?? [])

watch(isLoading, (newValue) => { isLoadingModel.value = newValue })
watch(values, () => {
  if (!values.value) return

  if (props.filter.type === 'Single') {
    multiSelected.value = multiSelected.value ?? values.value.defaultSelected ?? values.value.members[0]?.value
  } else {
    multiSelected.value = multiSelected.value ?? []
  }
}, { once: true })

function filterMemberMap (fil: FilterMember): filterOptions {
  if (fil.members && fil.members.length > 0) {
    return {
      id: fil.value ?? fil.label,
      label: fil.label,
      children: fil.members?.map(filterMemberMap)
    }
  }
  return {
    id: fil.value,
    label: fil.label
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(isLoading))
      ? (_openBlock(), _createElementBlock("span", _hoisted_1))
      : (_unref(isError))
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(error) ?? 'Error while fetching data'), 1))
        : (_openBlock(), _createBlock(_unref(Treeselect), {
            key: 2,
            modelValue: multiSelected.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((multiSelected).value = $event)),
            multiple: _ctx.filter.type === 'Multi',
            options: options.value,
            alwaysOpen: false,
            placeholder: `All ${_ctx.filter.name}`,
            clearOnSelect: true,
            "disable-branch-nodes": false,
            "max-height": 200,
            clearable: _ctx.filter.type === 'Multi',
            searchable: _ctx.filter.type === 'Multi',
            limit: 1,
            valueConsistsOf: 'LEAF_PRIORITY',
            class: "select custom-select w-70"
          }, null, 8, ["modelValue", "multiple", "options", "placeholder", "clearable", "searchable"]))
  ]))
}
}

})