import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "py-6 h-full"
}
const _hoisted_2 = { class: "mt-10" }
const _hoisted_3 = { class: "flex mb-6" }
const _hoisted_4 = {
  key: 0,
  class: "body-1 body-alt font-bold"
}
const _hoisted_5 = {
  key: 1,
  class: "py-6 text-gray-200"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_FleetTabs = _resolveComponent("FleetTabs")!
  const _component_ListCardView = _resolveComponent("ListCardView")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_FleetCard = _resolveComponent("FleetCard")!
  const _component_Observer = _resolveComponent("Observer")!

  return (!_ctx.error)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PageTitle, {
          title: _ctx.$t('fleet.title')
        }, null, 8, ["title"]),
        _createVNode(_component_FleetTabs, { onTabSelected: _ctx.displayTabInfos }, null, 8, ["onTabSelected"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ListCardView, { onChangeView: _ctx.changeView }, null, 8, ["onChangeView"]),
            _createVNode(_component_SearchInput, {
              placeholder: `Search for ${_ctx.tabDisplayed}...`,
              "onUpdate:modelValue": [
                _ctx.searchAssets,
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
              ],
              modelValue: _ctx.search,
              class: "ml-4"
            }, null, 8, ["placeholder", "onUpdate:modelValue", "modelValue"])
          ]),
          _createElementVNode("div", {
            ref: "fleetList",
            class: _normalizeClass(["items-center justify-center gap-x-4 gap-y-8", _ctx.cardView ? 'grid' : '']),
            style: {"grid-template-columns":"repeat(auto-fill, minmax(280px, 1fr))"}
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vessels, (vessel) => {
              return (_openBlock(), _createBlock(_component_FleetCard, {
                key: vessel.id,
                "card-view": _ctx.cardView,
                id: vessel.id,
                name: vessel.name,
                type: vessel.type,
                course: vessel.heading,
                lastCourseUpdate: vessel.lastPositionUpdate,
                country: vessel.country_alpha2,
                length: vessel.loa,
                speed: vessel.speed,
                imo: vessel.imo,
                mmsi: vessel.mmsi,
                isFav: vessel.isFavorite,
                imgUrl: vessel.imageUrl,
                categoryId: _ctx.categoryId
              }, null, 8, ["card-view", "id", "name", "type", "course", "lastCourseUpdate", "country", "length", "speed", "imo", "mmsi", "isFav", "imgUrl", "categoryId"]))
            }), 128)),
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
              return _createElementVNode("span", {
                key: i,
                class: _normalizeClass(_ctx.cardView ? '' : 'w-full')
              }, [
                (_ctx.loadingList)
                  ? (_openBlock(), _createBlock(_component_FleetCard, {
                      key: i,
                      loadingCard: true,
                      "card-view": _ctx.cardView
                    }, null, 8, ["card-view"]))
                  : _createCommentVNode("", true)
              ], 2)
            }), 64)),
            (!_ctx.loadingList && !_ctx.vessels.length && _ctx.tabDisplayed)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t("fleet.notFound", { asset: _ctx.tabDisplayed })), 1))
              : _createCommentVNode("", true)
          ], 2),
          (_ctx.vessels.length && _ctx.observe)
            ? (_openBlock(), _createBlock(_component_Observer, {
                key: 0,
                onOnChange: _ctx.loadDetails
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createElementVNode("div", null, null, -1)
                ])),
                _: 1
              }, 8, ["onOnChange"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : (_ctx.error)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
}