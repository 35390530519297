import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "border text-slate-200 custom-scrollbar bg-bg-2 border-grey-600 shadow-sm invisible rounded-b-md",
  id: `element_subscription_popover`,
  style: {"position":"absolute","width":"220px","max-height":"300px","overflow-x":"hidden","overflow-y":"auto","transform":"translateY(-2px)"}
}
const _hoisted_2 = { class: "p-2" }
const _hoisted_3 = {
  key: 0,
  class: "h-[150px] flex items-center justify-center"
}
const _hoisted_4 = {
  key: 1,
  class: "h-[150px] flex items-center justify-center"
}
const _hoisted_5 = {
  key: 0,
  class: "pl-2"
}
const _hoisted_6 = {
  key: 2,
  class: "pl-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_attribute_row = _resolveComponent("attribute-row")!
  const _component_asset_row = _resolveComponent("asset-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_link, {
      to: { query : { undefined  }},
      style: {"position":"fixed","background":"transparent","inset":"0"}
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[0] || (_cache[0] = [
              _createElementVNode("i", { class: "fa fa-spinner fa-spin fa-2x" }, null, -1)
            ])))
          : (_ctx.items.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[1] || (_cache[1] = [
                _createElementVNode("div", { class: "text-center" }, [
                  _createElementVNode("i", { class: "fa fa-box-open fa-2x" }),
                  _createElementVNode("small", { class: "block" }, "List empty...")
                ], -1)
              ])))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.items, (item, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  (item.assets && item.assets.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.getLabel(_ctx.labels,item.attributeId).name), 1))
                    : (_openBlock(), _createBlock(_component_attribute_row, {
                        key: 1,
                        labels: _ctx.labels,
                        attribute: item
                      }, null, 8, ["labels", "attribute"])),
                  (item.assets)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.assets, (asset, index) => {
                          return (_openBlock(), _createBlock(_component_asset_row, {
                            key: index,
                            labels: _ctx.labels,
                            asset: asset,
                            attributeId: item.attributeId
                          }, null, 8, ["labels", "asset", "attributeId"]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
      ])
    ])
  ], 64))
}