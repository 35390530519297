import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Teleport as _Teleport, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "hidden" }
const _hoisted_2 = { class: "h-full w-full" }
const _hoisted_3 = {
  key: 0,
  style: {"position":"fixed","background":"rgba(0,0,0,.3)","inset":"0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_fleet_popover = _resolveComponent("fleet-popover")!
  const _component_toaster = _resolveComponent("toaster")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.isConnected()), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view),
      (_ctx.appStore.state.blockers > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_Teleport, { to: "#end-of-body" }, [
        (_ctx.$route.query.popover ==='subscription_popover' )
          ? (_openBlock(), _createBlock(_component_fleet_popover, { key: 0 }))
          : _createCommentVNode("", true)
      ])),
      _cache[3] || (_cache[3] = _createElementVNode("div", {
        id: "end-of-doc",
        style: {"z-index":"2"}
      }, null, -1)),
      _createVNode(_component_toaster),
      _createVNode(_component_v_dialog, {
        "max-width": "800",
        transition: "dialog-fade-transition",
        modelValue: _ctx.appStore.state.dialog.isVisible,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.appStore.state.dialog.isVisible) = $event)),
        scrollable: "",
        opacity: ".1",
        onAfterLeave: _ctx.appStore.action.dialog.hide
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_alert, {
            icon: "mdi-timeline-alert",
            prominent: "",
            border: "top",
            color: "red"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list, {
                items: _ctx.appStore.state.dialog.msgList,
                "bg-color": "error"
              }, null, 8, ["items"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "onAfterLeave"]),
      _createVNode(_component_v_snackbar, {
        modelValue: _ctx.appStore.state.toaster.isVisible,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.appStore.state.toaster.isVisible) = $event)),
        color: _ctx.appStore.state.toaster.color
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_btn, {
            icon: "mdi-close",
            color: "white",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.appStore.action.toaster.hide()))
          })
        ]),
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.appStore.state.toaster.msg) + " ", 1)
        ]),
        _: 1
      }, 8, ["modelValue", "color"])
    ])
  ], 64))
}