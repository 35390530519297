import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "10",
  height: "10",
  viewBox: "0 0 20 20",
  fill: "currentColor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      id: "timeline",
      transform: "translate(0 -0.006)"
    }, [
      _createElementVNode("path", { d: "M4.18106 15.5833H10.2922V13.9167H4.18106V15.5833ZM7.51439 10.8333H13.6255V9.16667H7.51439V10.8333ZM10.8477 6.08333H16.9588V4.41667H10.8477V6.08333ZM2.23661 20C1.79217 20 1.40328 19.8333 1.06995 19.5C0.736613 19.1667 0.569946 18.7778 0.569946 18.3333V1.66667C0.569946 1.22222 0.736613 0.833333 1.06995 0.5C1.40328 0.166667 1.79217 0 2.23661 0H18.9033C19.3477 0 19.7366 0.166667 20.0699 0.5C20.4033 0.833333 20.5699 1.22222 20.5699 1.66667V18.3333C20.5699 18.7778 20.4033 19.1667 20.0699 19.5C19.7366 19.8333 19.3477 20 18.9033 20H2.23661ZM2.23661 18.3333H18.9033V1.66667H2.23661V18.3333Z" })
    ], -1)
  ])))
}