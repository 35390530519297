import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]


export default /*@__PURE__*/_defineComponent({
  __name: 'custom-html-preview',
  props: {
    html: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", { innerHTML: _ctx.html }, null, 8, _hoisted_1))
}
}

})