import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "h-full w-full"
}

import { toRef } from 'vue'
import { FilterFormatted } from '@/core/model/filter.model'
import { useChartRequestor } from '@/components/analytics/charts/useChartRequestor'
import { ChartInfo } from '@/core/model/chart.model'
import { getVisualComponent } from '@/components/analytics/charts/visuals/index'
import ChartCardLoading from '@/components/analytics/charts/card.loading.vue'
import PError from '@/components/analytics/services/previews/error-preview.vue'
import { IGeneratorOption } from '@/core/charts/ichartgenerator.model'


export default /*@__PURE__*/_defineComponent({
  __name: 'chart-preview',
  props: {
    chart: {},
    filtersApplied: {}
  },
  setup(__props: any) {

const props = __props

const { values, error, isLoading, isError } = useChartRequestor(props.chart, toRef(props.filtersApplied))

const options: IGeneratorOption = {
  height: '125px',
  isDetailed: false,
  title: props.chart.label,
  description: props.chart.description,
  legends: {
    enable: false
  },
  margin: props.chart.type === 'PIE' ? undefined : [0, 0, 0, 0]
}


return (_ctx: any,_cache: any) => {
  return (_unref(isLoading))
    ? (_openBlock(), _createBlock(ChartCardLoading, { key: 0 }))
    : (_unref(isError))
      ? (_openBlock(), _createBlock(PError, {
          key: 1,
          error: _unref(error)
        }, null, 8, ["error"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["h-full w-full flex", _ctx.chart.type === 'PIE' ? 'flex-row' : 'flex-col'])
          }, [
            (_unref(values))
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(getVisualComponent)(_ctx.chart.type)), {
                  key: 0,
                  values: _unref(values),
                  options: options,
                  class: _normalizeClass(["mx-[-1rem] h-full boorder", _ctx.chart.type === 'PIE' ? 'w-3/5' : 'mx-[-1rem]'])
                }, null, 8, ["values", "class"]))
              : _createCommentVNode("", true)
          ], 2)
        ]))
}
}

})