import { BaseChartGenerator } from '@/core/charts/baseChart.service'
import { IChartGenerator } from '@/core/charts/ichartgenerator.model'
import { PlotOptions } from '@/core/model/chart.model'

class UnstackBarChart extends BaseChartGenerator implements IChartGenerator {
  protected get Type (): string { return 'column' }

  protected plotOptions (detailed: boolean) : PlotOptions {
    const model = super.plotOptions(detailed)
    if (model.series) {
      model.series.stacking = undefined
    }
    return model
  }
}

export default new UnstackBarChart()
