import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

interface Props { active?: boolean }

export default /*@__PURE__*/_defineComponent({
  __name: 'tag',
  props: {
    active: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["py-2.5 inline-flex rounded-3xl px-4 bg-bg-0 mt-2 mr-2 hover:bg-bg-2 hover:text-text-alt select-none max-h-8.5 body body-1 body-alt !font-bold items-center min-w-fit", _ctx.active ? 'bg-semantic-activate !text-bg-0 hover:bg-semantic-activate' : ''
    ])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})