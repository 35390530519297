import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mb-6 lg:mb-0 text-gray-200 h-fit relative z-2" }
const _hoisted_2 = { class: "h-full w-full relative z-2 text-gray-200 relative pou-4 flex flex-col sm:flex-row" }
const _hoisted_3 = { class: "flex me-2 sm:m-0 flex-col sm:flex-row" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { class: "p-2.5 bg-bg-3 rounded-full mr-4" }
const _hoisted_6 = { class: "font-bold flex sm:hidden items-center" }
const _hoisted_7 = {
  key: 1,
  class: "capitalize"
}
const _hoisted_8 = {
  key: 2,
  class: "skeleton pl-0"
}
const _hoisted_9 = { class: "absolute w-full h-full z-3 top-0 left-0 rounded-xl fleet-card__filter" }
const _hoisted_10 = { class: "flex justify-end body-2 bottom-4 left-0 z-3 text-text-title absolute w-full px-4" }
const _hoisted_11 = { class: "flex flex-col items-center" }
const _hoisted_12 = { class: "w-full h-full mt-2 sm:mt-0 hidden sm:block" }
const _hoisted_13 = { class: "font-bold flex items-center" }
const _hoisted_14 = {
  key: 1,
  class: "capitalize"
}
const _hoisted_15 = {
  key: 2,
  class: "skeleton pl-0"
}
const _hoisted_16 = { class: "body-2 body-alt" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LeftArrowIcon = _resolveComponent("LeftArrowIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_VesselLengthIcon = _resolveComponent("VesselLengthIcon")!
  const _component_FavouriteButton = _resolveComponent("FavouriteButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: { name: 'fleet' },
          class: "flex items-center sm:items-baseline mb-4"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_LeftArrowIcon, { class: "h-5 w-5 stroke-gray-100" })
              ]),
              _createElementVNode("h3", _hoisted_6, [
                (_ctx.flag)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(`fi fi-${_ctx.flag} mr-3`)
                    }, null, 2))
                  : _createCommentVNode("", true),
                (!_ctx.loading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.name), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8))
              ])
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", {
          class: "w-67 h-45 mr-0 sm:mr-4 relative bg-cover rounded-xl bg-center",
          style: _normalizeStyle(`background-image: url('${_ctx.imgUrl ? _ctx.imgUrl : _ctx.img}');`)
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_VesselLengthIcon, { class: "mb-1 h-4 w-auto fill-text-title stroke-text-title" }),
                _createElementVNode("span", null, _toDisplayString(`${_ctx.lengthFormatted} m` || "N/A"), 1)
              ])
            ])
          ]),
          _createVNode(_component_FavouriteButton, {
            onChangeFavStatus: _ctx.changeFavStatus,
            isFav: _ctx.isFavourite,
            "stroke-fav": '#0C111C',
            stroke: 'white',
            "fill-fav": '#0C111C',
            fill: 'none',
            loading: _ctx.favLoading
          }, null, 8, ["onChangeFavStatus", "isFav", "loading"])
        ], 4)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("h3", _hoisted_13, [
          (_ctx.flag)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(`fi fi-${_ctx.flag} mr-3`)
              }, null, 2))
            : _createCommentVNode("", true),
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.name), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_15))
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("p", {
            class: _normalizeClass(["font-bold mt-2", { 'skeleton-small': _ctx.loading }])
          }, [
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.type), 1))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("p", {
            class: _normalizeClass(["font-bold mt-2 arialized", { 'skeleton-small': _ctx.loading }])
          }, [
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "body body-alt" }, "Bridge Number : ", -1)),
                  _createTextVNode(_toDisplayString(_ctx.bridgeNumber || "N/A"), 1)
                ]))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("p", {
            class: _normalizeClass(["font-bold mt-2 arialized", { 'skeleton-small': _ctx.loading }])
          }, [
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("span", _hoisted_19, [
                  _cache[1] || (_cache[1] = _createElementVNode("span", { class: "body body-alt" }, "Bridge Email : ", -1)),
                  _createTextVNode(_toDisplayString(_ctx.bridgeEmail || "N/A"), 1)
                ]))
              : _createCommentVNode("", true)
          ], 2)
        ])
      ])
    ])
  ]))
}