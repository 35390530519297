import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "overflow-hidden rounded-xl h-full" }
const _hoisted_2 = { class: "flex flex-col h-full relative p-4 pb-0" }

import PChart from '@/components/analytics/services/previews/chart-preview.vue'
import PChartAndKpi from '@/components/analytics/services/previews/chart-and-kpi-preview.vue'
import PCustom from '@/components/analytics/services/previews/custom-html-preview.vue'
import PKpi from '@/components/analytics/services/previews/kpi-preview.vue'
import PNone from '@/components/analytics/services/previews/none-preview.vue'
import PHeader from '@/components/analytics/services/previews/preview-header.vue'
import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import { ServiceModel } from '@/core/model/service.model'
import { FilterFormatted } from '@/core/model/filter.model'
import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'preview',
  props: {
    service: {},
    filters: {}
  },
  setup(__props: any) {

const props = __props

const link = computed(() => {
  if (props.filters === undefined) {
    return {
      name: 'analyticsDetail',
      params: {
        analyticsId: props.service.id.toString()
      }
    }
  }

  return {
    name: 'analyticsDetail',
    params: {
      analyticsId: props.service.id.toString()
    },
    query: {
      filter: encodeURI(JSON.stringify(props.filters))
    }
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(GenericCardShell, {
    link: link.value,
    class: _normalizeClass([{ 'col-span-1 lg:col-span-2': _ctx.service.size !== 'Small'}, "aspect-video"])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(PHeader, {
            title: _ctx.service.title
          }, null, 8, ["title"]),
          (_ctx.service.previewType === 'Chart')
            ? (_openBlock(), _createBlock(PChart, {
                key: 0,
                chart: _ctx.service.chart,
                "filters-applied": _ctx.filters ?? []
              }, null, 8, ["chart", "filters-applied"]))
            : (_ctx.service.previewType === 'ChartAndKPI')
              ? (_openBlock(), _createBlock(PChartAndKpi, {
                  key: 1,
                  chart: _ctx.service.chart,
                  kpi: _ctx.service.kpi,
                  "filters-applied": _ctx.filters ?? []
                }, null, 8, ["chart", "kpi", "filters-applied"]))
              : (_ctx.service.previewType === 'KPI')
                ? (_openBlock(), _createBlock(PKpi, {
                    key: 2,
                    kpi: _ctx.service.kpi,
                    "filters-applied": _ctx.filters ?? []
                  }, null, 8, ["kpi", "filters-applied"]))
                : (_ctx.service.previewType === 'Custom')
                  ? (_openBlock(), _createBlock(PCustom, {
                      key: 3,
                      html: _ctx.service.customHtml
                    }, null, 8, ["html"]))
                  : (_openBlock(), _createBlock(PNone, { key: 4 }))
        ])
      ])
    ]),
    _: 1
  }, 8, ["link", "class"]))
}
}

})