import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full h-10 relative" }
const _hoisted_2 = { class: "overflow-auto h-[calc(100%-20px)] custom-scrollbar" }
const _hoisted_3 = { class: "w-full mb-5" }
const _hoisted_4 = { class: "border-b border-bg-3" }
const _hoisted_5 = { class: "py-2.5 text-text-prim !font-bold text-right" }
const _hoisted_6 = { class: "border-b border-bg-3" }
const _hoisted_7 = { class: "py-2.5 text-text-prim !font-bold text-right" }
const _hoisted_8 = { class: "border-b border-bg-3" }
const _hoisted_9 = { class: "py-2.5 text-text-prim !font-bold text-right" }
const _hoisted_10 = { class: "border-b border-bg-3" }
const _hoisted_11 = { class: "py-2.5 text-text-prim !font-bold text-right" }
const _hoisted_12 = { class: "border-b border-bg-3" }
const _hoisted_13 = { class: "flex items-center py-2.5 text-text-prim !font-bold text-right justify-end" }
const _hoisted_14 = { class: "w-full mb-5" }
const _hoisted_15 = {
  class: "py-2.5 text-text-prim !font-bold",
  colspan: "2"
}
const _hoisted_16 = { class: "w-full mb-5" }
const _hoisted_17 = { class: "py-2.5 text-text-alt" }
const _hoisted_18 = { class: "py-2.5 text-text-prim !font-bold text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseModal = _resolveComponent("CloseModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "fixed lg:absolute bottom-0 lg:top-6 -left-0 lg:-left-96 w-screen lg:w-[375px] h-[400px] lg:h-auto border bg-bg-0 rounded-b-none lg:rounded-b-xl rounded-t-xl py-5 px-6",
      style: _normalizeStyle(`border: 1px solid ${_ctx.getKpiColour()}; z-index: 400`)
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CloseModal, {
          onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close-modal')))
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", _hoisted_3, [
          _createElementVNode("tr", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("td", { class: "py-2.5 text-text-alt" }, "Vessel", -1)),
            _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.operation.name), 1)
          ]),
          _createElementVNode("tr", _hoisted_6, [
            _cache[3] || (_cache[3] = _createElementVNode("td", { class: "py-2.5 text-text-alt" }, "Type", -1)),
            _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.operation.type), 1)
          ]),
          _createElementVNode("tr", _hoisted_8, [
            _cache[4] || (_cache[4] = _createElementVNode("td", { class: "py-2.5 text-text-alt" }, "From", -1)),
            _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.formatDate(_ctx.operation.activity.startTime)), 1)
          ]),
          _createElementVNode("tr", _hoisted_10, [
            _cache[5] || (_cache[5] = _createElementVNode("td", { class: "py-2.5 text-text-alt" }, "To", -1)),
            _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.formatDate(_ctx.operation.activity.endTime)), 1)
          ]),
          _createElementVNode("tr", _hoisted_12, [
            _cache[6] || (_cache[6] = _createElementVNode("td", { class: "py-2.5 text-text-alt" }, "Activity", -1)),
            _createElementVNode("td", _hoisted_13, [
              _createElementVNode("div", {
                class: "rounded-full h-3 w-3 mr-1 min-w-3",
                style: _normalizeStyle(`background: ${_ctx.getKpiColour()}`)
              }, null, 4),
              _createTextVNode(" " + _toDisplayString(_ctx.operation.activity.name), 1)
            ])
          ])
        ]),
        _createElementVNode("table", _hoisted_14, [
          _cache[7] || (_cache[7] = _createElementVNode("tr", { class: "border-b border-bg-3" }, [
            _createElementVNode("td", {
              class: "py-2.5 text-text-alt",
              colspan: "2"
            }, " Commentary from the vessel ")
          ], -1)),
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.operation.activity.commentary || "N/A"), 1)
          ])
        ]),
        _createElementVNode("table", _hoisted_16, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.operation.activity.properties, (kpi, k) => {
            return (_openBlock(), _createElementBlock("tr", {
              class: "border-b border-bg-3",
              key: k
            }, [
              _createElementVNode("td", _hoisted_17, _toDisplayString(kpi.name), 1),
              _createElementVNode("td", _hoisted_18, _toDisplayString(_ctx.formatNumber(kpi.value)), 1)
            ]))
          }), 128))
        ])
      ])
    ], 4),
    _createElementVNode("div", {
      class: "fixed w-screen h-screen top-0 left-0 modal",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModalOnBg && _ctx.closeModalOnBg(...args)))
    })
  ], 64))
}