
import { defineComponent, inject } from 'vue'
import { IPlanningFilterManager } from '@/core/managers/planningFilter/planningFilterManager'
import { IAssetFilter, IPlanningFilter } from '@/core/managers/planningFilter/planningFilterModel'
import { ITimeLineHelper } from '@/core/managers/timeLineHelper'
import timelinePopover from '@/views/feat/timelinePopover.vue'
import { easePoly, interpolateNumber, select, timeFormat } from 'd3'
import { IEntriesForAssetManager } from '@/core/managers/entriesForAsset/entriesForAssetManager'
import { AssetRow } from '@/core/managers/entriesForAsset/entriesForAssetsModel'
import timeLineRowLeft from '@/views/feat/timeLineRowLeft.vue'
import timeLineRowRight from '@/views/feat/timeLineRowRight.vue'

export default defineComponent({
  name: 'timelinePage',
  components: {
    timeLineRowLeft,
    timeLineRowRight,
    timelinePopover
  },
  watch: {
    _1: {
      immediate: true,
      handler: function (value :string) {
        if (value && value !== '-1') {
          function _scrollByXOffset (offset:number, duration: number) {
            const scrollableX = document.getElementById('headerScroll')

            if (scrollableX) {
              const currentScrollLeft = scrollableX.scrollLeft
              const targetScrollLeft = offset

              select(scrollableX)
                .transition()
                .duration(duration)
                .ease(easePoly.exponent(3))
                .tween('scroll', () => {
                  return function (t:number) {
                    scrollableX.scrollLeft = interpolateNumber(currentScrollLeft, targetScrollLeft)(t)
                  }
                })
            }
          }

          this.$nextTick(() => {
            const duration = this.$route.query.scroll ? 3000 : 0
            const offset = this.timelineHelper.scrollTo(this.$route.params.rangeTimeId as string)
            _scrollByXOffset(offset, duration)
          })
        }
      }
    }
  },
  computed: {
    _1 ():string {
      if (!this.pageLoading && !!this.$route.params.rangeTimeId) {
        return this.$route.params.rangeTimeId as string
      } else {
        return '-1'
      }
    }
  },
  directives: {
    scroll: {
      mounted (element: HTMLElement, binding) {
        const _trigger = document.getElementById('headerScroll')
        if (_trigger) {
          element.style.transform = `translateX(${(_trigger as HTMLElement).scrollLeft * -1}px)`
          _trigger.addEventListener('scroll', function (e) {
            element.style.transform = `translateX(${(e.target as HTMLElement).scrollLeft * -1}px)`
          })
        }
      }
    }
  },
  setup () {
    const timelineHelper = inject('timelineHelper') as ITimeLineHelper
    const controller = new AbortController()
    const planningFilterManager = inject('planningFilterManager') as IPlanningFilterManager
    const entriesForAssetManager = inject('entriesForAssetManager') as IEntriesForAssetManager
    return { planningFilterManager, controller, timelineHelper, entriesForAssetManager }
  },
  data () {
    return { filterText: '', pageLoading: true, assetLoading: false, assetList: [] as AssetRow[], planningFilter: {} as IPlanningFilter, position: [] as Record<string, string>[], assetId: '-1', periodId: '-1', toggle: false }
  },
  async created () {
    this.planningFilter = this.planningFilterManager.init
    this.pageLoading = true
    this.planningFilter = await this.planningFilterManager.fetch(this.controller.signal)
    this.pageLoading = false
    let contractId = '-1'
    if (this.$route.params.contractId) {
      contractId = this.$route.params.contractId as string
    }
    if (this.planningFilter.contracts.length > 0) {
      if (contractId === '-1' || !this.planningFilter.contracts.find(x => x.id === parseInt(contractId))) {
        contractId = this.planningFilter.contracts[0].id.toString()
        this.$router.push({ params: { contractId } })
      }
      await this.fetchContract(contractId)
    }
  },
  mounted () {
    const eventDelegate = document.getElementById('eventDelegate') as HTMLElement
    const popoverMaxHeight = 300
    eventDelegate.addEventListener('click', (e:MouseEvent) => {
      const dataset = (e.target as HTMLElement).dataset
      if (dataset.popover && dataset.popover === 'true') {
        this.assetId = dataset.assetId as string
        this.periodId = dataset.periodId as string
        this.toggle = true
        this.position.push({ left: e.clientX + 'px' })
        if (document.body.clientHeight < e.clientY + popoverMaxHeight) {
          this.position.push({ bottom: e.clientY + 'px' })
        } else {
          this.position.push({ top: e.clientY + 'px' })
        }
      }
      if (dataset.redirect && dataset.redirect === 'true') {
        this.$router.push({ name: 'period', params: { contractId: this.$route.params.contractId, assetId: dataset.assetId, rangeTimeId: this.$route.params.rangeTimeId } })
      }
    })
  },
  methods: {
    contractChange (contractId: string) {
      this.$router.push({ params: { contractId } })
      this.fetchContract(contractId)
    },
    fetchContract: async function (contractId:string) {
      this.assetLoading = true
      this.assetList = await this.entriesForAssetManager.fetch(contractId, this.controller.signal)
      this.assetLoading = false
    },
    filterByContract (items : IAssetFilter[], contractId : number): IAssetFilter[] {
      return items.filter(x => x.contractId === contractId)
    },
    onIncrementMonth (value: number) {
      const oldYear = (this.$route.params.rangeTimeId as string).split('-')[0]
      const oldMonth = (this.$route.params.rangeTimeId as string).split('-')[1]
      const oldDate = new Date(parseInt(oldYear), (parseInt(oldMonth) - 1) + value)
      const formatA = timeFormat('%Y-%m')
      this.$router.push({ params: { rangeTimeId: formatA(oldDate) }, query: { scroll: 'duration' } })
    }
  },
  beforeUnmount () {
    this.controller.abort()
  }
})
