import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex flex-col h-full relative"
}
const _hoisted_2 = { class: "flex flex-col lg:flex-row h-full" }
const _hoisted_3 = {
  key: 0,
  class: "h-full flex flex-col w-full m-auto p-4"
}
const _hoisted_4 = {
  key: 1,
  class: "h-full flex flex-col w-full m-auto p-4"
}

import { ref, toRef } from 'vue'
import { FilterFormatted } from '@/core/model/filter.model'
import { useChartRequestor } from '@/components/analytics/charts/useChartRequestor'
import { KpiModel } from '@/core/model/kpi.model'
import { ChartInfo } from '@/core/model/chart.model'
import Tools from '@/core/services/tools'
import { getVisualComponent } from '@/components/analytics/charts/visuals/index'
import ChartCardLoading from '@/components/analytics/charts/card.loading.vue'
import ChartError from '@/components/analytics/charts/chart.error.vue'
import { IGeneratorOption } from '@/core/charts/ichartgenerator.model'


export default /*@__PURE__*/_defineComponent({
  __name: 'chart',
  props: {
    chart: {},
    filtersApplied: {}
  },
  setup(__props: any) {

const props = __props

const { values, error, isLoading, isError } = useChartRequestor(props.chart, toRef(props.filtersApplied))

const kpiDetailsRef = ref<KpiModel>()

function calculateHeight () {
  // if (detailedRef.value) {
  //   return '430px'
  // }

  if (!Tools.isEmpty(kpiDetailsRef.value) && props.chart.type !== 'PIE') {
    return '87px'
  }

  return '125px'
}

const options: IGeneratorOption = {
  height: '430px',
  isDetailed: true,
  title: props.chart.label,
  description: props.chart.description,
  legends: {
    enable: true
  }
}


return (_ctx: any,_cache: any) => {
  return (_unref(isLoading))
    ? (_openBlock(), _createBlock(ChartCardLoading, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_unref(isError) || _unref(values) === undefined)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(ChartError, {
                  error: _unref(error),
                  options: options
                }, null, 8, ["error"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(getVisualComponent)(_ctx.chart.type)), {
                  values: _unref(values),
                  options: options
                }, null, 8, ["values"]))
              ]))
        ])
      ]))
}
}

})