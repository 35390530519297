import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "timeline-day-header body-1 text-bg-0 bg-text-prim !font-bold p-3" }
const _hoisted_2 = {
  key: 0,
  class: "my-3"
}
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = {
  class: "my-3",
  style: {"margin-left":"5rem"}
}
const _hoisted_5 = {
  key: 0,
  class: "text-center body body-1 !font-bold"
}
const _hoisted_6 = {
  key: 1,
  class: "text-center body body-2"
}

import { Activity, TimelineAction, TimelineModalContent, TimelineModel } from '@/core/model/timeline.model'
import FormatService from '@/core/services/format.service'
import dayActivities from './day-activities.vue'
import { ref, Ref, onMounted } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'details',
  props: {
    timeline: {},
    openModal: {},
    modalContent: {}
  },
  emits: ["showActivity"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit
const shownActivity = ref<Activity|undefined>(undefined)

onMounted(() => {
  const scrollable = document.getElementById('timeline-scrollable')
  if (scrollable) {
    scrollable.scrollTop = scrollable.scrollHeight
  }
})

function mustDisplayLocation (contexts: Array<TimelineAction>, contextIndex: number, dayIndex: number) {
  if (dayIndex === 0 && contextIndex === 0) return true

  if (contextIndex === 0) {
    const previousDay = props.timeline[dayIndex - 1]
    const lastContextOfPreviousDay = previousDay.timeSheetContexts[previousDay.timeSheetContexts.length - 1]
    return lastContextOfPreviousDay.location !== contexts[contextIndex].location
  }

  return contexts[contextIndex - 1].location !== contexts[contextIndex].location
}

function mustDisplayContext (contexts: Array<TimelineAction>, contextIndex: number, dayIndex: number) {
  if (dayIndex === 0 && contextIndex === 0) return true

  if (mustDisplayLocation(contexts, contextIndex, dayIndex)) return true

  if (contextIndex === 0) {
    const previousDay = props.timeline[dayIndex - 1]
    const lastContextOfPreviousDay = previousDay.timeSheetContexts[previousDay.timeSheetContexts.length - 1]
    return lastContextOfPreviousDay.context !== contexts[contextIndex].context
  }

  return contexts[contextIndex - 1].context !== contexts[contextIndex].context
}

function showActivity (activityIndex: number, contextIndex: number, dayIndex: number) {
  const activity = props.timeline[dayIndex].timeSheetContexts[contextIndex].activities[activityIndex]
  shownActivity.value = activity
  emit('showActivity', activity)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: "timeline-scrollable",
    class: _normalizeClass(["max-h-[800px] overflow-y-auto overflow-x-hidden custom-scrollbar pt-2", {'show-details': _ctx.openModal }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeline, (day, day_index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: day_index,
        class: "w-full pt-2 border-t"
      }, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(FormatService).formatDate(day.date)), 1),
        (day_index > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(day.timeSheetContexts, (context, context_index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: context_index }, [
              _createElementVNode("div", _hoisted_4, [
                (mustDisplayLocation(day.timeSheetContexts, context_index, day_index))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(context.location), 1))
                  : _createCommentVNode("", true),
                (mustDisplayContext(day.timeSheetContexts, context_index, day_index))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(context.context), 1))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(dayActivities, {
                activities: context.activities,
                "shown-activity": shownActivity.value,
                onShowActivity: (activity_index) => showActivity(activity_index, context_index, day_index)
              }, null, 8, ["activities", "shown-activity", "onShowActivity"])
            ], 64))
          }), 128))
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "my-3" }, null, -1))
      ]))
    }), 128))
  ], 2))
}
}

})