import { IHttpProvider } from '@/core/provider/httpApiProvider'
import { IAssetFilter, IPlanningFilter } from '@/core/managers/planningFilter/planningFilterModel'
import { ApiContractFilter } from '@/core/managers/planningFilter/planningFilterApi'
import { IPlanningFilterMapper } from '@/core/managers/planningFilter/planningFilterMapper'

export interface IPlanningFilterManager {
  fetch: (signal : AbortSignal)=> Promise<IPlanningFilter>
  init : IPlanningFilter
  assetNotFound : IAssetFilter
}

export const CreatePlanningFilterManager = function (httpProvider :IHttpProvider, periodFilterMapper: IPlanningFilterMapper): IPlanningFilterManager {
  return {
    init: {
      contracts: [],
      assets: []
    },
    assetNotFound: {
      id: -1,
      name: 'not found',
      contractId: -1,
      contractName: 'not found',
      status: 'not found',
      status_color: '#ddddd',
      image_url: require('@/assets/img/noAsset.png')
    },
    fetch: async function (signal : AbortSignal) {
      const _result = await httpProvider.get<ApiContractFilter[]>(process.env.VUE_APP_URL + 'planning/contracts', signal)
      return periodFilterMapper.fetchDecoupling(_result)
    }
  }
}
