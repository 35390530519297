import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = { class: "mb-1" }
const _hoisted_3 = { class: "d-flex justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_date_input = _resolveComponent("v-date-input")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_locale_provider = _resolveComponent("v-locale-provider")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_number_input = _resolveComponent("v-number-input")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_row, { class: "h-100" }, {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_v_col, {
            key: 0,
            cols: "12",
            class: "h-100 d-flex align-center justify-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_progress_circular, {
                indeterminate: "",
                color: "white"
              })
            ]),
            _: 1
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_v_col, { cols: "8" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_toolbar, { color: "transparent" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_app_bar_nav_icon, {
                              icon: "mdi-arrow-left",
                              to: {name : 'period', params : {contractId: this.$route.params.contractId, assetId : this.$route.params.assetId,  rangeTimeId : _ctx.$route.params.rangeTimeId }}
                            }, null, 8, ["to"]),
                            _createVNode(_component_v_toolbar_title, { class: "flex-grow-1" }, {
                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                _createElementVNode("h1", null, "New period", -1)
                              ])),
                              _: 1
                            }),
                            _createElementVNode("div", _hoisted_1, [
                              _createVNode(_component_v_img, {
                                width: "50",
                                rounded: "",
                                class: "me-2",
                                src: _ctx.planningFilterHelper.findAssetById(_ctx.planningFilter.assets, parseInt(_ctx.$route.params.assetId) ).image_url 
                              }, null, 8, ["src"]),
                              _createTextVNode(_toDisplayString(_ctx.planningFilterHelper.findAssetById(_ctx.planningFilter.assets, parseInt(_ctx.$route.params.assetId) ).name), 1)
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_form, {
                          onSubmit: _withModifiers(_ctx.onSubmit, ["prevent"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_divider),
                            _cache[21] || (_cache[21] = _createElementVNode("h3", { class: "my-3" }, [
                              _createTextVNode("Selected period "),
                              _createElementVNode("span", { style: {"color":"#dc1212"} }, "*")
                            ], -1)),
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_locale_provider, { locale: "en" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { cols: "3" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_date_input, {
                                          label: "start date",
                                          disabled: !_ctx.item.canEditStartDate,
                                          readonly: !_ctx.item.canEditStartDate,
                                          variant: "outlined",
                                          placeholder: "dd/mm/yyyy",
                                          min: _ctx.item.minCalendarTime,
                                          max: _ctx.item.maxCalendarTime,
                                          modelValue: _ctx.item.startDate,
                                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.startDate) = $event)),
                                          rules: [_ctx.required],
                                          required: "",
                                          density: "compact",
                                          "prepend-icon": "",
                                          "prepend-inner-icon": "$calendar"
                                        }, null, 8, ["disabled", "readonly", "min", "max", "modelValue", "rules"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { cols: "3" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          type: "time",
                                          label: "start time",
                                          disabled: !_ctx.item.canEditStartDate,
                                          readonly: !_ctx.item.canEditStartDate,
                                          modelValue: _ctx.item.startTime,
                                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.startTime) = $event)),
                                          rules: [_ctx.required],
                                          "validate-on": "blur lazy",
                                          "prepend-icon": "",
                                          "prepend-inner-icon": "mdi-clock"
                                        }, null, 8, ["disabled", "readonly", "modelValue", "rules"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { cols: "3" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_date_input, {
                                          label: "end date",
                                          variant: "outlined",
                                          disabled: !_ctx.item.canEditEndDate,
                                          readonly: !_ctx.item.canEditEndDate,
                                          min: _ctx.item.minCalendarTime,
                                          max: _ctx.item.maxCalendarTime,
                                          modelValue: _ctx.item.endDate,
                                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.endDate) = $event)),
                                          placeholder: "dd/mm/yyyy",
                                          density: "compact",
                                          rules: [_ctx.required],
                                          required: "",
                                          "prepend-icon": "",
                                          "prepend-inner-icon": "$calendar"
                                        }, null, 8, ["disabled", "readonly", "min", "max", "modelValue", "rules"])
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_v_col, { cols: "3" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_text_field, {
                                          type: "time",
                                          label: "end time",
                                          disabled: !_ctx.item.canEditEndDate,
                                          readonly: !_ctx.item.canEditEndDate,
                                          modelValue: _ctx.item.endTime,
                                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.endTime) = $event)),
                                          rules: [_ctx.required],
                                          required: "",
                                          "validate-on": "blur lazy",
                                          "prepend-icon": "",
                                          "prepend-inner-icon": "mdi-clock"
                                        }, null, 8, ["disabled", "readonly", "modelValue", "rules"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_divider, { class: "my-3" }),
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "3" }, {
                                  default: _withCtx(() => [
                                    _cache[11] || (_cache[11] = _createElementVNode("h4", { class: "mb-1" }, [
                                      _createTextVNode("On/Off"),
                                      _createElementVNode("span", { style: {"color":"#dc1212"} }, "*")
                                    ], -1)),
                                    _createVNode(_component_v_radio_group, {
                                      inline: "",
                                      ripple: false,
                                      modelValue: _ctx.item.isOn,
                                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.item.isOn) = $event)),
                                      color: "primary"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_v_radio, {
                                          label: "On",
                                          value: true,
                                          onClick: _ctx.resetReason
                                        }, null, 8, ["onClick"]),
                                        _createVNode(_component_v_radio, {
                                          label: "Off",
                                          value: false,
                                          onClick: _ctx.resetReason
                                        }, null, 8, ["onClick"])
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_col, { cols: "3" }, {
                                  default: _withCtx(() => [
                                    (_ctx.item.isOn)
                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                          _cache[12] || (_cache[12] = _createElementVNode("h4", { class: "mb-1" }, [
                                            _createTextVNode("Daily rate"),
                                            _createElementVNode("span", { style: {"color":"#dc1212"} }, "*")
                                          ], -1)),
                                          _createVNode(_component_v_number_input, {
                                            density: "compact",
                                            variant: "outlined",
                                            step: 5,
                                            min: 0,
                                            max: 100,
                                            modelValue: _ctx.item.dailyRate,
                                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.item.dailyRate) = $event)),
                                            required: "",
                                            rules: [_ctx.required]
                                          }, null, 8, ["modelValue", "rules"])
                                        ], 64))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_col, { cols: "3" }, {
                                  default: _withCtx(() => [
                                    _cache[13] || (_cache[13] = _createElementVNode("h4", { class: "mb-1" }, [
                                      _createTextVNode("Reason"),
                                      _createElementVNode("span", { style: {"color":"#dc1212"} }, "*")
                                    ], -1)),
                                    _createVNode(_component_v_select, {
                                      items: _ctx.reasonListFilter(_ctx.item.reasonList, _ctx.item.isOn),
                                      placeholder: "Please select",
                                      modelValue: _ctx.item.reasonId,
                                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.item.reasonId) = $event)),
                                      rules: [_ctx.required],
                                      required: ""
                                    }, null, 8, ["items", "modelValue", "rules"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  cols: "3",
                                  offset: "3"
                                }, {
                                  default: _withCtx(() => [
                                    _cache[14] || (_cache[14] = _createElementVNode("h4", { class: "mb-1" }, [
                                      _createTextVNode("Project"),
                                      _createElementVNode("span", { style: {"color":"#dc1212"} }, "*")
                                    ], -1)),
                                    _createVNode(_component_v_select, {
                                      items: _ctx.item.projectList,
                                      placeholder: "Please select",
                                      modelValue: _ctx.item.projectId,
                                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.item.projectId) = $event)),
                                      rules: [_ctx.required],
                                      required: ""
                                    }, null, 8, ["items", "modelValue", "rules"])
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_v_col, { cols: "3" }, {
                                  default: _withCtx(() => [
                                    _cache[15] || (_cache[15] = _createElementVNode("h4", { class: "mb-1" }, [
                                      _createTextVNode("Client"),
                                      _createElementVNode("span", { style: {"color":"#dc1212"} }, "*")
                                    ], -1)),
                                    _createVNode(_component_v_select, {
                                      items: _ctx.item.clientList,
                                      placeholder: "Please select",
                                      modelValue: _ctx.item.clientId,
                                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.item.clientId) = $event)),
                                      rules: [_ctx.required],
                                      required: ""
                                    }, null, 8, ["items", "modelValue", "rules"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            (_ctx.item.robList.length>0)
                              ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { cols: "12" }, {
                                      default: _withCtx(() => _cache[16] || (_cache[16] = [
                                        _createElementVNode("h3", null, [
                                          _createTextVNode("ROB"),
                                          _createElementVNode("span", { style: {"color":"#dc1212"} }, "*")
                                        ], -1)
                                      ])),
                                      _: 1
                                    }),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.robList, (rob, index) => {
                                      return (_openBlock(), _createBlock(_component_v_col, {
                                        cols: "3",
                                        key: index
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("h4", _hoisted_2, [
                                            _createTextVNode(_toDisplayString(rob.product), 1),
                                            _cache[17] || (_cache[17] = _createElementVNode("span", { style: {"color":"#dc1212"} }, "*", -1))
                                          ]),
                                          _createVNode(_component_v_text_field, {
                                            type: "number",
                                            min: "0",
                                            density: "compact",
                                            modelValue: rob.quantity,
                                            "onUpdate:modelValue": ($event: any) => ((rob.quantity) = $event),
                                            suffix: rob.unit,
                                            required: ""
                                          }, null, 8, ["modelValue", "onUpdate:modelValue", "suffix"])
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            _createVNode(_component_v_divider, { class: "my-3" }),
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => _cache[18] || (_cache[18] = [
                                    _createElementVNode("h3", null, "Comment", -1)
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_textarea, {
                                      density: "compact",
                                      variant: "outlined",
                                      "bg-color": "surface-light",
                                      modelValue: _ctx.item.description,
                                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.item.description) = $event))
                                    }, null, 8, ["modelValue"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createElementVNode("footer", _hoisted_3, [
                              _createVNode(_component_v_btn, {
                                to: {name : 'period', params : {contractId : this.$route.params.contractId, assetId : _ctx.$route.params.assetId, rangeTimeId : _ctx.$route.params.rangeTimeId }}
                              }, {
                                default: _withCtx(() => _cache[19] || (_cache[19] = [
                                  _createTextVNode("Cancel")
                                ])),
                                _: 1
                              }, 8, ["to"]),
                              _createVNode(_component_v_btn, {
                                type: "submit",
                                color: "#f1d31a",
                                disabled: _ctx.pending,
                                class: "ms-1"
                              }, {
                                default: _withCtx(() => [
                                  _cache[20] || (_cache[20] = _createTextVNode("Valid")),
                                  (_ctx.pending)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createTextVNode("...")
                                      ], 64))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }, 8, ["disabled"])
                            ])
                          ]),
                          _: 1
                        }, 8, ["onSubmit"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { cols: "4" })
          ], 64))
    ]),
    _: 1
  }))
}