import { createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  "data-element-id": "searchBarPopover",
  "data-sticky-parent": "stickyMenuRight",
  class: "absolute",
  style: {"width":"350px"}
}
const _hoisted_2 = {
  class: "flex items-center dark:bg-slate-800 rounded-md bg-white/50 pl-2.5 py-2.5",
  style: {"box-shadow":"0 0 10px rgba(169,184,219,.4)"}
}
const _hoisted_3 = {
  class: "p-1 dark:bg-slate-800 rounded-md bg-white/50 py-2.5 mt-2.5",
  style: {"box-shadow":"0 0 10px rgba(169,184,219,.4)"}
}
const _hoisted_4 = { class: "flex items-center pb-2.5 border-b border-gray-700" }
const _hoisted_5 = {
  key: 0,
  class: "pr-3 custom-scrollbar mt-2.5 overflow-x-hidden overflow-y-auto",
  style: {"height":"300px"},
  "data-keyboard": "ul"
}
const _hoisted_6 = ["data-item-id"]
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = { class: "grow" }
const _hoisted_9 = { class: "font-bolt flex items-center" }
const _hoisted_10 = {
  class: "text-ellipsis overflow-hidden whitespace-nowrap text-md",
  style: {"width":"170px"}
}
const _hoisted_11 = {
  class: "text-sm text-slate-300 text-ellipsis overflow-hidden whitespace-nowrap",
  style: {"width":"170px"}
}
const _hoisted_12 = {
  key: 0,
  class: "text-slate-300 flex"
}
const _hoisted_13 = { class: "text-sm ml-1" }
const _hoisted_14 = {
  key: 1,
  class: "flex flex-col items-center py-4 dark:text-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_wifi_icon = _resolveComponent("wifi-icon")!
  const _directive_focus = _resolveDirective("focus")!
  const _directive_keyboard = _resolveDirective("keyboard")!
  const _directive_sticky = _resolveDirective("sticky")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("i", { class: "fa-solid fa-search inline-block dark:text-slate-300" }, null, -1)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "data-keyboard": "input",
        class: "text-sm rounded pl-2 outline-none dark:bg-transparent dark:text-slate-300 dark:placeholder:text-slate-300",
        placeholder: "Search assets",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSearch($event)))
      }, null, 544), [
        [_directive_focus]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.list.length>0)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_4, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "grow text-xl dark:text-white font-bold" }, "Search", -1)),
              _createVNode(_component_router_link, {
                to: { query :  {..._ctx.$route.query, popover: undefined}  },
                class: "text-white"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createElementVNode("span", { class: "fa-stack" }, [
                    _createElementVNode("i", { class: "fa-solid text-slate-600 fa-circle fa-circle fa-stack-2x" }),
                    _createElementVNode("i", {
                      class: "fa-regular fa-circle-xmark fa-stack-1x dark:text-white",
                      style: {"font-size":"1.2em"}
                    })
                  ], -1)
                ])),
                _: 1
              }, 8, ["to"])
            ]),
            (_ctx.trackList.length>0)
              ? _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trackList, (item, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      class: "py-2.5 dark:bg-slate-800 dark:hover:bg-slate-600 hover:bg-gray-100 dark:text-white border-b border-slate-600"
                    }, [
                      _createElementVNode("a", {
                        href: "#",
                        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onClick($event)), ["prevent"])),
                        "data-item-id": item.assetCode,
                        class: "flex items-center"
                      }, [
                        _createElementVNode("img", {
                          src: item.assetImg,
                          alt: item.assetName,
                          style: {"width":"60px"},
                          class: "rounded mr-2.5 ml-2.5"
                        }, null, 8, _hoisted_7),
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, _toDisplayString(item.assetName), 1)
                          ]),
                          _createElementVNode("div", _hoisted_11, _toDisplayString(item.assetTypeName), 1)
                        ]),
                        (item.isVessel && item.isUnderContract)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createVNode(_component_wifi_icon, { class: "text-xs h-4 w-4 fill-text-title" }),
                              _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.durationStr(item.lastPosition)), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ], 8, _hoisted_6)
                    ]))
                  }), 128))
                ])), [
                  [_directive_keyboard]
                ])
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createElementBlock("div", _hoisted_14, [
            (_ctx.loading)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fa-solid fa-spinner fa-spin" }, null, -1)),
                  _cache[6] || (_cache[6] = _createElementVNode("span", null, "Loading", -1))
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _cache[7] || (_cache[7] = _createElementVNode("i", { class: "fa-solid fa-empty-set" }, null, -1)),
                  _cache[8] || (_cache[8] = _createElementVNode("span", null, "No data", -1))
                ], 64))
          ]))
    ])
  ])), [
    [_directive_sticky]
  ])
}