import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-2.5" }

import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import PaginateSlider from '@/map/views/components/drawers/left/vesselDetail/kpi/slider/slider-pagination.vue'
import ViewportSlider from '@/map/views/components/drawers/left/vesselDetail/kpi/slider/slider-viewport.vue'
import { Manager as FleetManager } from '@/core/managers/fleet.manager'
import { ServiceModel } from '@/core/model/service.model'
import { State } from '@/store'
import * as SettingsManager from '@/core/managers/settings.manager'
import sliderComposable from '@/map/views/components/drawers/left/vesselDetail/kpi/slider/slider.composable'


export default /*@__PURE__*/_defineComponent({
  __name: 'slider-container',
  props: {
    assetId: {},
    categoryId: {}
  },
  setup(__props: any) {

const props = __props

const store = useStore<State>()
const position = sliderComposable.position
const direction = sliderComposable.direction

const kpiList = ref<ServiceModel[]>([])

const assetFilter = computed(() => store.state.filtersSettings?.id_AssetFilter ?? 0)
const periodFilter = computed(() => store.state.filtersSettings?.id_PeriodFilter ?? 0)

watch(position, (newVal, oldVal) => sliderComposable.updateDirection(newVal, oldVal))

onMounted(async () => {
  if (!store.state.filtersSettings) {
    SettingsManager.Manager.filtersSettings()
  }

  const response = await FleetManager.detail(props.categoryId, props.assetId)

  if (response.data) {
    kpiList.value = response.data.services
    sliderComposable.initEvent(kpiList.value.length)
  }
})

onUnmounted(() => sliderComposable.removeEvent())

function onMouseOver () {
  sliderComposable.stopEvent()
}
function onMouseLeave () {
  sliderComposable.nextEvent()
}
function onClick (value : number) {
  sliderComposable.changePosition(value)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ViewportSlider, {
      sources: kpiList.value,
      current: _unref(position),
      direction: _unref(direction),
      "asset-id": _ctx.assetId,
      "asset-filter": assetFilter.value,
      periodFilter: periodFilter.value
    }, null, 8, ["sources", "current", "direction", "asset-id", "asset-filter", "periodFilter"]),
    _createVNode(PaginateSlider, {
      count: kpiList.value.length,
      current: _unref(position),
      onMouseover: onMouseOver,
      onMouseleave: onMouseLeave,
      onCurrent: onClick
    }, null, 8, ["count", "current"])
  ]))
}
}

})