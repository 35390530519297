import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "absolute rounded-lg bg-gray-900 text-white",
  style: {"width":"300px","top":"10px","left":"10px","z-index":"2","box-shadow":"0 0 10px rgba(169,184,219,.4)"}
}
const _hoisted_2 = {
  key: 0,
  style: {"height":"500px"},
  class: "flex flex-col items-center justify-center"
}
const _hoisted_3 = {
  key: 1,
  style: {"height":"500px"},
  class: "flex flex-col items-center justify-center"
}
const _hoisted_4 = { class: "flex p-2.5" }
const _hoisted_5 = { class: "grow" }
const _hoisted_6 = { class: "text-xl font-bold mb-0" }
const _hoisted_7 = { class: "text-sm text-slate-300" }
const _hoisted_8 = { class: "mt-2 relative px-2.5" }
const _hoisted_9 = { class: "relative img-gradient" }
const _hoisted_10 = ["src", "alt"]
const _hoisted_11 = {
  class: "absolute",
  style: {"bottom":"5px","right":"10px"}
}
const _hoisted_12 = {
  class: "text-slate-300 font-bold text-sm",
  style: {"line-height":"2em"}
}
const _hoisted_13 = { class: "flex justify-between p-2.5" }
const _hoisted_14 = { class: "flex flex-col items-center mx-2" }
const _hoisted_15 = { class: "text-text-prim text-sm font-bold mt-1.5" }
const _hoisted_16 = { class: "flex flex-col items-center" }
const _hoisted_17 = { class: "text-text-prim text-sm font-bold mt-1.5" }
const _hoisted_18 = { class: "flex flex-col items-center mr-2" }
const _hoisted_19 = { class: "text-text-prim text-sm font-bold" }
const _hoisted_20 = { class: "mt-2 py-2 px-2.5 border-b border-t border-gray-700 flex" }
const _hoisted_21 = { class: "grow" }
const _hoisted_22 = { class: "text-sm text-slate-300" }
const _hoisted_23 = { class: "flex justify-center items-center" }
const _hoisted_24 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_wifi_icon = _resolveComponent("wifi-icon")!
  const _component_SpeedometerIcon = _resolveComponent("SpeedometerIcon")!
  const _component_Compass = _resolveComponent("Compass")!
  const _component_VesselLengthIcon = _resolveComponent("VesselLengthIcon")!
  const _component_wifiIcon = _resolveComponent("wifiIcon")!
  const _component_slider_container = _resolveComponent("slider-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("i", { class: "fa fa-spinner fa-spin fa-2xl" }, null, -1)
        ])))
      : (_ctx.error)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
            _createElementVNode("i", { class: "mdi-alert fa-2xl" }, null, -1)
          ])))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createElementVNode("section", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.asset.assetName), 1),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.asset.assetTypeName), 1)
              ]),
              _createVNode(_component_router_link, {
                to: { query: {  ..._ctx.$route.query, panelDetail: undefined }},
                class: "text-white",
                style: {"margin-right":"-5px"}
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createElementVNode("span", { class: "fa-stack" }, [
                    _createElementVNode("i", { class: "fa-solid text-slate-600 fa-circle fa-circle fa-stack-2x" }),
                    _createElementVNode("i", {
                      class: "fa-regular fa-circle-xmark fa-stack-1x dark:text-white",
                      style: {"font-size":"1.2em"}
                    })
                  ], -1)
                ])),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("section", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("img", {
                  src: _ctx.asset.assetImg,
                  alt: _ctx.asset.assetName,
                  class: "rounded-lg",
                  style: {"width":"280px"}
                }, null, 8, _hoisted_10)
              ]),
              _createElementVNode("small", _hoisted_11, [
                _createVNode(_component_wifi_icon, { class: "h-4 w-4 fill-text-title" }),
                _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.durationStr(_ctx.asset.lastPosition)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_SpeedometerIcon, { class: "h-4 w-4 fill-text-title" }),
                _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.asset.speed) + " kn", 1)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_Compass, { class: "h-4 w-4 fill-text-title" }),
                _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.asset.heading) + "°", 1)
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_VesselLengthIcon, { class: "w-12 fill-text-title" }),
                _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.asset.assetLength) + " m", 1)
              ])
            ]),
            _createElementVNode("section", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-lg font-bold" }, "Last position received", -1)),
                _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.formatDate(_ctx.asset.lastPosition)), 1)
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_wifiIcon, { class: "h-4 w-4 fill-text-title" })
              ])
            ]),
            (!_ctx.loading && _ctx.asset.assetId)
              ? (_openBlock(), _createBlock(_component_slider_container, {
                  key: 0,
                  categoryId: _ctx.asset.categoryId,
                  assetId: _ctx.asset.assetId
                }, null, 8, ["categoryId", "assetId"]))
              : _createCommentVNode("", true),
            _createElementVNode("a", {
              href: _ctx.asset.assetMsFleetUrl,
              class: "btn-view-more btn-gradient-yellow text-gray-800",
              title: "view more"
            }, "View more", 8, _hoisted_24)
          ], 64))
  ]))
}