import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "Capa_1",
  "enable-background": "new 0 0 512 512",
  height: "512",
  viewBox: "0 0 512 512",
  width: "512",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", null, [
      _createElementVNode("g", null, [
        _createElementVNode("path", { d: "m356 0h-200c-33.084 0-60 26.916-60 60v392c0 33.084 26.916 60 60 60h200c33.084 0 60-26.916 60-60v-392c0-33.084-26.916-60-60-60zm20 452c0 11.028-8.972 20-20 20h-200c-11.028 0-20-8.972-20-20v-20h240zm0-60h-240v-332c0-11.028 8.972-20 20-20h200c11.028 0 20 8.972 20 20zm-120-312c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20z" })
      ])
    ], -1)
  ])))
}