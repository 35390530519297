import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  height: "682.66669pt",
  viewBox: "-21 -21 682.66669 682.66669",
  width: "682.66669pt",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m640 320c0 85.472656-33.285156 165.835938-93.726562 226.273438-60.4375 60.441406-140.800782 93.726562-226.273438 93.726562v-50c148.875 0 270-121.125 270-270s-121.125-270-270-270-270 121.125-270 270c0 88.613281 42.742188 170.351562 114.890625 221l68.859375-68.855469v167.855469h-167.855469l63.203125-63.203125c-36.136718-26.929687-66.488281-61.371094-88.511718-100.71875-26.554688-47.4375-40.585938-101.410156-40.585938-156.078125 0-85.472656 33.285156-165.835938 93.726562-226.273438 60.4375-60.441406 140.800782-93.726562 226.273438-93.726562s165.835938 33.285156 226.273438 93.726562c60.441406 60.4375 93.726562 140.800782 93.726562 226.273438zm-307.054688 48.300781 78.753907 78.75 35.351562-35.351562-78.75-78.753907c1.105469-4.132812 1.699219-8.46875 1.699219-12.945312 0-18.507812-10.058594-34.648438-25-43.296875v-164.203125h-50v164.203125c-14.941406 8.648437-25 24.789063-25 43.296875 0 27.613281 22.386719 50 50 50 4.476562 0 8.8125-.59375 12.945312-1.699219zm0 0" }, null, -1)
  ])))
}