import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative h-full" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowForward = _resolveComponent("ArrowForward")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_toaster = _resolveComponent("toaster")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: _ctx.mapId,
      style: {"width":"100%","height":"100%","z-index":"0"}
    }, null, 8, _hoisted_2),
    (_ctx.code !=='')
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: { name : 'map', query : { panelDetail : _ctx.codeFormated,  flyTo : _ctx.codeFormated, assetCircle: _ctx.codeFormated, setBlue : this.$route.params.categoryId === '3' ? _ctx.codeFormated : undefined}},
          class: "text-white rounded-md bg-bg-0 px-4 py-2.5 absolute bottom-5 right-5",
          style: {"z-index":"400"}
        }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("span", null, "Open in map", -1)),
            _createVNode(_component_ArrowForward, { class: "h-6 w-6 inline-block ml-2.5" })
          ]),
          _: 1
        }, 8, ["to"]))
      : _createCommentVNode("", true),
    _createVNode(_component_toaster),
    _createVNode(_component_loader)
  ]))
}