import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-full w-full"
}
const _hoisted_2 = { class: "w-2/5" }
const _hoisted_3 = {
  class: "body body-2 body-alt block",
  style: {"font-family":"'arial'"}
}
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "body body-1" }
const _hoisted_7 = { class: "ml-3 !font-bold" }
const _hoisted_8 = { class: "w-3/5 h-full" }

import { KpiInfo, KpiModel } from '@/core/model/kpi.model'
import ColoursService from '@/core/services/colours.service'
import FormatService from '@/core/services/format.service'
import Gauge from '@/components/analytics/kpi/visuals/gauge.kpi.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'gauge',
  props: {
    kpi: {},
    values: {}
  },
  setup(__props: any) {

const props = __props


return (_ctx: any,_cache: any) => {
  return (_ctx.values)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["h-full w-full flex flex-row", props.kpi.type === 'SOLID_GAUGE' ? 'justify-between': ''])
        }, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values.kpis, (kpiValue, i) => {
              return (_openBlock(), _createElementBlock("div", { key: i }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", {
                      class: "h-2 w-2 rounded-full mr-1",
                      style: _normalizeStyle(`background-color: ${_unref(ColoursService).getColour(kpiValue.label ?? _ctx.kpi.label)}; min-width: 0.5rem`)
                    }, null, 4),
                    _createElementVNode("div", {
                      class: "truncate",
                      title: kpiValue.label ?? _ctx.kpi.label
                    }, [
                      _createElementVNode("span", null, _toDisplayString(kpiValue.label ?? _ctx.kpi.label), 1)
                    ], 8, _hoisted_5)
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(FormatService).formatNumber(+kpiValue.value)) + " " + _toDisplayString(kpiValue.unit), 1)
                ])
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values.kpis, (kpiValue, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                class: "relative"
              }, [
                _createVNode(Gauge, {
                  title: _ctx.kpi.label,
                  value: kpiValue.value ? kpiValue.value.toString() : '0',
                  unit: kpiValue.unit,
                  type: _ctx.kpi.type,
                  class: "boorder"
                }, null, 8, ["title", "value", "unit", "type"])
              ]))
            }), 128))
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}
}

})