import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  height: "682pt",
  viewBox: "-71 -21 682 682.66669",
  width: "682pt",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "m292 541.25c0 13.808594-11.191406 25-25 25s-25-11.191406-25-25 11.191406-25 25-25 25 11.191406 25 25zm245-466.25v490c0 41.359375-33.640625 75-75 75h-390c-41.355469 0-75-33.640625-75-75v-490c0-41.359375 33.644531-75 75-75h390c41.359375 0 75 33.640625 75 75zm-490 0v365h440v-365c0-13.785156-11.214844-25-25-25h-390c-13.785156 0-25 11.214844-25 25zm440 490v-75h-440v75c0 13.785156 11.214844 25 25 25h390c13.785156 0 25-11.214844 25-25zm0 0" }, null, -1)
  ])))
}