import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  version: "1.1",
  id: "Calque_2",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 93.3333969 93.3333359",
  "enable-background": "new 0 0 93.3333969 93.3333359",
  "xml:space": "preserve",
  fill: "currentColor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<g><path d=\"M10.00002,9.9999895v83.3333511H73.333374V9.9999895H10.00002z M66.666687,16.6666775v69.9999771H16.666708V16.6666775H66.666687z\"></path><path d=\"M19.9993629,6.6666565H76.666687v76.6665039l6.666687,0.0001831V-0.0000102H20.000021L19.9993629,6.6666565z\"></path><rect x=\"23.333374\" y=\"46.7472687\" width=\"36.6309967\" height=\"6.6666665\"></rect><rect x=\"23.333374\" y=\"60.0575714\" width=\"36.6309967\" height=\"6.6666665\"></rect><rect x=\"23.333374\" y=\"33.3678703\" width=\"36.6309967\" height=\"6.6666665\"></rect></g>", 1)
  ])))
}