import { createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "block cursor-pointer mt-2" }
const _hoisted_2 = { class: "text-ellipsis overflow-hidden w-64 inline-block whitespace-nowrap flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onToggle(!_ctx.state.toggle))),
      style: {"display":"none"}
    }, null, 32),
    _createElementVNode("span", _hoisted_2, [
      (_ctx.state.loading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa-solid fa-spinner fa-spin-pulse text-white" }, null, -1)),
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "ml-2 text-white" }, "loading...", -1))
          ], 64))
        : (_ctx.state.error !== '' )
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fa-regular fa-triangle-exclamation" }, null, -1)),
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "ml-2 text-white" }, "Error occurs...", -1))
            ], 64))
          : (_ctx.state.list && _ctx.state.list.length === 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fa-regular fa-triangle-exclamation" }, null, -1)),
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "ml-2 text-white" }, "No concession", -1))
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createElementVNode("i", {
                  class: _normalizeClass(["fa-lg text-white", {' fa-solid  fa-square-check' : _ctx.state.toggle, 'fa-regular fa-square' :!_ctx.state.toggle}])
                }, null, 2),
                _cache[7] || (_cache[7] = _createElementVNode("span", { class: "text-ellipsis overflow-hidden w-64 inline-block whitespace-nowrap ml-2 text-white" }, "Display all concessions", -1))
              ], 64))
    ])
  ]))
}