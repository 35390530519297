
import GenericCardShell from '@/components/cards/GenericCardShell.vue'
import { FleetDetailModel } from '@/core/model/fleet.model'
import {
  Activity,
  TimelineModalContent,
  TimelineModel
} from '@/core/model/timeline.model'
import router from '@/router'
import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import TimelineZoom from '@/components/buttons/TimelineZoom.vue'
import FleetAssetTimelineDetail from '@/components/fleet/history/timeline/details.vue'
import FleetAssetTimelineModal from '@/components/fleet/FleetAssetTimelineModal.vue'

export default defineComponent({
  name: 'FleetAssetTimeline',
  components: {
    GenericCardShell,
    // FleetPeriodFilter,
    FleetAssetTimelineModal,
    FleetAssetTimelineDetail,
    // FleetAssetTimelineAgenda,
    TimelineZoom
    // ToggleTimeline
  },
  props: {
    timeline: { type: Array as PropType<TimelineModel[]>, required: true },
    vesselDetails: {
      type: Object as PropType<FleetDetailModel>,
      required: true
    },
    error: String,
    loading: Boolean
  },
  setup (props, context) {
    const currentDay = ref(0)
    const changeDay = ($event: number) => {
      currentDay.value = $event
    }

    // const reversedTimeline = computed(() => {
    //   let timeline = [...props.timeline]
    //   timeline = timeline.reverse()
    //   return timeline
    // })

    const openModal = ref(false)
    const initialModalContentState: TimelineModalContent = {
      name: '',
      type: '',
      activity: {} as Activity
    }
    const modalContent = reactive({ ...initialModalContentState })
    const closeModal = () => {
      Object.assign(modalContent, initialModalContentState)
      openModal.value = false
    }
    const showDetail = (stack: Activity) => {
      Object.assign(modalContent, {
        name: props.vesselDetails.name,
        type: props.vesselDetails.type,
        activity: stack
      })
      openModal.value = true
    }

    const zoomLevel = ref(
      router.currentRoute.value.query.zoom
        ? +router.currentRoute.value.query.zoom
        : 50
    )
    const handleZoomLevel = ($event: string) => {
      if ($event === 'in') {
        zoomLevel.value += 10
      } else {
        zoomLevel.value > 40 ? (zoomLevel.value -= 10) : (zoomLevel.value -= 0)
      }
      const zoomQuery = { zoom: zoomLevel.value }
      const tabQuery = router.currentRoute.value.query.tab
      const timelineQuery = router.currentRoute.value.query.timeline
      const query = !tabQuery && !timelineQuery
        ? zoomQuery
        : tabQuery && !timelineQuery
          ? { tab: tabQuery, ...zoomQuery }
          : !tabQuery && timelineQuery
              ? { timeline: timelineQuery, ...zoomQuery }
              : { tab: tabQuery, timeline: timelineQuery, ...zoomQuery }
      router.replace({ query })
    }

    const selectedTimeline = ref(
      router.currentRoute.value.query.timeline
        ? router.currentRoute.value.query.timeline
        : 'journey'
    )

    const selectedPeriod = ref('L1W')

    const selectPeriod = ($event: string) => {
      selectedPeriod.value = $event
      context.emit('periodSelected', $event)
    }

    const changeTimelineView = ($event: string) => {
      selectedTimeline.value = $event
      const timelineQuery = { timeline: selectedTimeline.value }
      const query = !router.currentRoute.value.query.tab
        ? timelineQuery
        : { tab: router.currentRoute.value.query.tab, ...timelineQuery }
      router.replace({ query })
    }

    return {
      currentDay,
      changeDay,
      openModal,
      modalContent,
      closeModal,
      showDetail,
      zoomLevel,
      handleZoomLevel,
      selectedTimeline,
      changeTimelineView,
      selectPeriod
      // reversedTimeline
    }
  }
})
