import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "p-6 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportAttributeTabLoading = _resolveComponent("ReportAttributeTabLoading")!
  const _component_ReportInstanceLoading = _resolveComponent("ReportInstanceLoading")!
  const _component_GenericCardShell = _resolveComponent("GenericCardShell")!

  return (_openBlock(), _createElementBlock(_Fragment, null, _renderList(1, (i) => {
    return _createElementVNode("div", { key: i }, [
      _createElementVNode("div", null, [
        _createElementVNode("div", null, [
          _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "font-display text-white text-2xl my-4 font-bold skeleton-title" }, null, -1)),
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(1, (j) => {
            return _createElementVNode("div", { key: j }, [
              _createElementVNode("div", null, [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (k) => {
                  return _createVNode(_component_GenericCardShell, {
                    key: k,
                    class: "mb-4"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_ReportAttributeTabLoading),
                        _createVNode(_component_ReportInstanceLoading)
                      ])
                    ]),
                    _: 2
                  }, 1024)
                }), 64))
              ])
            ])
          }), 64))
        ])
      ])
    ])
  }), 64))
}