import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  version: "1.1",
  id: "Calque_2",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 481 481",
  "enable-background": "new 0 0 481 481",
  "xml:space": "preserve",
  fill: "currentColor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", null, [
      _createElementVNode("path", { d: "M249.95,86.7c-2.5-2.5-6-4-9.5-4c-3.6,0-7,1.4-9.5,4l-87.8,87.8c-5.3,5.3-5.3,13.8,0,19.1s13.8,5.3,19.1,0l64.7-64.7 v338.6c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V128.9l64.7,64.7c2.6,2.6,6.1,4,9.5,4c3.5,0,6.9-1.3,9.5-4 c5.3-5.3,5.3-13.8,0-19.1L249.95,86.7z" }),
      _createElementVNode("path", { d: "M439.55,0H41.35c-7.5,0-13.5,6-13.5,13.5v99.3c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V27h371.3v85.8 c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V13.5C453.05,6,447.05,0,439.55,0z" })
    ], -1)
  ])))
}